import React from 'react'
import style from './AuthLayout.module.css'

import { Typography } from 'antd'
import { ReactComponent as Logo } from '../../images/escudo.svg'

const { Title } = Typography

const AuthLayout = ({ children }) => {
  return (
    <div className={style.container}>
      <div className={style.background} />
      <div className={style.aside}>
        <div className={style.content}>
          <Logo className={style.logo} />
          <Title>Control de Calidad <span>UVA</span></Title>
          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
