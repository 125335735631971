import React, { useState, useEffect, createContext } from 'react'

import firebase from '../lib/firebase'
import useAuth from '../hooks/useAuth'

const ProfileContext = createContext()

const { Provider, Consumer } = ProfileContext

const ProfileProvider = ({ children }) => {
  const profile = useProfileProvider()

  return <Provider value={profile}>{children}</Provider>
}

const useProfileProvider = () => {
  const [profile, setProfile] = useState({})
  const [loading, setLoading] = useState(true)
  const { user, setUser } = useAuth()

  const currentUser = firebase.auth().currentUser

  const updateEmail = (email) => {
    return new Promise((resolve, reject) => {
      currentUser.updateEmail(email)
        .then(() => {
          return firebase.firestore().collection('users').doc(user.uid).update({ email: email })
        })
        .then(() => {
          setProfile({ ...profile, email })
          setUser({ ...user, email })
          resolve()
        })
        .catch((error) => {
          let errorMessage = ''
          switch (error.code) {
            case 'auth/requires-recent-login':
              return resolve(true)
            case 'auth/invalid-email':
              errorMessage = 'La cuenta no es un email válido'
              break
            case 'auth/email-already-in-use':
              errorMessage = 'La cuenta de email a la que quieres cambiar ya está siendo utilizada por otro usuario'
              break
            default:
              errorMessage = error.message
              break
          }
          reject(errorMessage)
        })
    })
  }

  const updateProfile = (update) => {
    return new Promise((resolve, reject) => {
      const displayName = update.firstName.split(' ')[0]
      firebase.firestore().collection('users').doc(user.uid).update(update)
        .then(() => {
          return currentUser.updateProfile({
            displayName
          })
        })
        .then(() => {
          setProfile({ ...profile, ...update })
          setUser({ ...user, displayName })
          resolve()
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  }

  useEffect(() => {
    if (user.ready) {
      setLoading(true)
      firebase.firestore().collection('users').doc(user.uid).get()
        .then((doc) => {
          if (doc.exists) {
            setProfile({ ...doc.data() })
          } else {
            console.log('Este usuario no existe')
          }
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          console.log(error.message)
        })
    }
  }, [user.ready])

  return { profile, loading, updateEmail, updateProfile }
}

export { ProfileContext, ProfileProvider, Consumer as ProfileConsumer }
