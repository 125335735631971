import React, { useState, useEffect, createContext } from 'react'

import firebase from '../lib/firebase'

const AuthContext = createContext()

const { Provider, Consumer } = AuthContext

const AuthProvider = ({ children }) => {
  const auth = useAuthProvider()

  return <Provider value={auth}>{children}</Provider>
}

const localUser = window.localStorage.getItem('authUser')
const initialState = localUser ? JSON.parse(localUser) : null

const useAuthProvider = () => {
  const [user, setUser] = useState(initialState)
  const currentUser = firebase.auth().currentUser

  const signIn = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          let errorMessage = ''
          switch (error.code) {
            case 'auth/invalid-email':
              errorMessage = 'La cuenta no es un email válido.'
              break
            case 'auth/user-disabled':
              errorMessage = 'Esta cuenta se encuentra inactiva.'
              break
            case 'auth/user-not-found':
              errorMessage = 'Esta cuenta no pertenece a ningún usuario de la plataforma.'
              break
            case 'auth/wrong-password':
              errorMessage = 'Tu contraseña es incorrecta.'
              break
            case 'auth/too-many-requests':
              errorMessage = 'El acceso a esta cuenta ha sido bloqueado temporalmente debido a varios intentos fallidos .'
              break
            default:
              errorMessage = error.message
              break
          }
          reject(errorMessage)
        })
    })
  }

  const signOut = () => {
    return new Promise((resolve, reject) => {
      firebase.auth().signOut()
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  }

  const signUp = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(email, password)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          let errorMessage = ''
          switch (error.code) {
            case 'auth/email-already-in-use':
              errorMessage = 'Ya existe una cuenta con este email.'
              break
            case 'auth/invalid-email':
              errorMessage = 'La cuenta no es un email válido.'
              break
            case 'auth/operation-not-allowed':
              errorMessage = 'Esta operación no esta permitida temporalmente.'
              break
            case 'auth/weak-password':
              errorMessage = 'Tu contraseña es muy débil, debe tener al menos 6 caracteres.'
              break
            default:
              errorMessage = error.message
              break
          }
          reject(errorMessage)
        })
    })
  }

  const sendPasswordResetEmail = (email) => {
    return new Promise((resolve, reject) => {
      firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          let errorMessage = ''
          switch (error.code) {
            case 'auth/invalid-email':
              errorMessage = 'La cuenta no es un email válido.'
              break
            case 'auth/user-not-found':
              errorMessage = 'Esta cuenta no pertenece a ningún usuario de la plataforma.'
              break
            default:
              errorMessage = error.message
              break
          }
          reject(errorMessage)
        })
    })
  }

  const updatePassword = (newPassword) => {
    return new Promise((resolve, reject) => {
      currentUser.updatePassword(newPassword)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          let errorMessage = ''
          switch (error.code) {
            case 'auth/requires-recent-login':
              return resolve(true)
            case 'auth/weak-password':
              errorMessage = 'Tu contraseña es muy débil, debe tener al menos 6 caracteres.'
              break
            default:
              errorMessage = error.message
              break
          }
          reject(errorMessage)
        })
    })
  }

  const reauthenticate = (password) => {
    return new Promise((resolve, reject) => {
      const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, password)
      currentUser.reauthenticateWithCredential(credential)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          let errorMessage = ''
          switch (error.code) {
            case 'auth/invalid-email':
              errorMessage = 'La cuenta no es un email válido.'
              break
            case 'auth/user-not-found':
              errorMessage = 'Esta cuenta no pertenece a ningún usuario de la plataforma.'
              break
            case 'auth/wrong-password':
              errorMessage = 'Tu contraseña es incorrecta.'
              break
            default:
              errorMessage = error.message
              break
          }
          reject(errorMessage)
        })
    })
  }

  const handleAuth = async (user) => {
    try {
      if (user) {
        const userObject = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          provider: user.providerData[0].providerId,
          ready: true
        }
        const idTokenResult = await user.getIdTokenResult()
        if (idTokenResult?.claims.admin) {
          userObject.admin = true
        }
        await firebase.firestore().collection('users').doc(user.uid).set({
          uid: user.uid,
          email: user.email,
          provider: user.providerData[0].providerId,
          lastLogin: firebase.firestore.FieldValue.serverTimestamp()
        }, { merge: true })
        setUser(userObject)
      } else {
        setUser(null)
      }
    } catch (error) {
      console.error(error)
      setUser(null)
    }
  }

  useEffect(() => {
    const authListener = firebase.auth().onAuthStateChanged(handleAuth)
    return () => authListener()
  }, [])

  useEffect(() => {
    if (user) {
      window.localStorage.setItem('authUser', JSON.stringify({ ...user, ready: false }))
    } else {
      window.localStorage.clear()
      window.sessionStorage.clear()
    }
  }, [user])

  return { user, setUser, signIn, signOut, signUp, sendPasswordResetEmail, reauthenticate, updatePassword }
}

export { AuthContext, AuthProvider, Consumer as AuthConsumer }
