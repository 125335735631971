import { useEffect, useReducer } from 'react'

import firebase from '../lib/firebase'

const initialState = {
  data: [],
  loading: false,
  error: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD':
      return {
        ...state,
        loading: true
      }
    case 'SUCCESS':
      return {
        data: action.payload,
        loading: false,
        error: null
      }

    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

const useLogs = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'LOAD' })
    return firebase.firestore().collection('logs').orderBy('sendedAt', 'desc').limit(6).onSnapshot((snapshot) => {
      const logs = []
      snapshot.forEach((doc) => {
        logs.push(doc.data())
      })
      dispatch({
        type: 'SUCCESS',
        payload: logs
      })
    }, (error) => {
      console.log(error)
    })
  }, [])

  return [state]
}

export default useLogs
