import React, { useState, useEffect } from 'react'

import { Card, Row, Col, Typography, Checkbox, Select, Switch, Button, Space, Popconfirm, message, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import { useHistory } from 'react-router-dom'

import PageHeader from '../layout/PageHeader'
import SurveyItem from '../modules/SurveyItem'

import useSurveyAsAdmin from '../../hooks/useSurveyAsAdmin'
import useCampusData from '../../hooks/useCampusData'

const { Title, Text } = Typography

const NewSurvey = () => {
  const localData = window.sessionStorage.getItem('new-survey')
  const { state, setData, addQuestion, sortQuestion, setQuestion, deleteQuestion, duplicateQuestion, addSurvey } = useSurveyAsAdmin(null, localData ? JSON.parse(localData) : null)
  const { data, questions, saving } = state
  const { campus } = useCampusData()
  const [errors, setErrors] = useState({})
  const history = useHistory()

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%'
    },
    value: data.onlyCampus,
    options: campus.map((item) => ({ label: item.name, value: item.name })),
    onChange: (value) => {
      setData({ onlyCampus: value })
    },
    maxTagCount: 'responsive'
  }

  const handleSubmit = () => {
    validateData()
      .then(() => {
        return addSurvey()
      })
      .then(() => {
        window.sessionStorage.removeItem('new-survey')
        message.success('La encuesta se ha guardado correctamente.', () => history.push('/'))
      })
      .catch((error) => {
        message.error(error.message)
      })
  }

  const handleSortStart = () => {
    document.body.style.cursor = 'ns-resize'
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    sortQuestion({ oldIndex, newIndex })
    document.body.style.cursor = 'default'
  }

  const validateData = () => {
    return new Promise((resolve, reject) => {
      let errorsObject = {}
      if (data.title.trim() === '') {
        errorsObject = { ...errorsObject, title: true }
      }
      questions.forEach((item, i) => {
        if (item.question.trim() === '') {
          errorsObject = { ...errorsObject, ['item' + i]: true }
          return
        }
        if (item.type === 'radio' || item.type === 'checkbox' || item.type === 'select' || item.type === 'slider') {
          if (item.options === null) {
            errorsObject = { ...errorsObject, ['item' + i]: true }
          } else if (item.options.length < 1) {
            errorsObject = { ...errorsObject, ['item' + i]: true }
          }
        } else if (item.type === 'condition') {
          if (item.subQuestion === null) {
            errorsObject = { ...errorsObject, ['item' + i]: true }
          } else if (item.subQuestion.trim() === '') {
            errorsObject = { ...errorsObject, ['item' + i]: true }
          }
        } else if (item.type === 'section') {
          if (!questions[i + 1] || questions[i + 1]?.type === 'section') {
            errorsObject = { ...errorsObject, ['item' + i]: true }
          }
        }
      })
      if (Object.entries(errorsObject).length === 0) {
        setErrors({})
        resolve()
      } else {
        setErrors(errorsObject)
        reject(new Error('Hay algunos errores que hay que solucionar antes de guardar la encuesta.'))
      }
    })
  }

  useEffect(() => {
    window.sessionStorage.setItem('new-survey', JSON.stringify({ data, questions }))
  }, [data, questions])

  return (
    <>
      <PageHeader
        title='Nueva encuesta'
        subtitle='Administrador'
        back='Inicio'
      >
        <Text type='secondary'>Llena los siguientes campos para crear una encuesta. Empieza por el título, enseguida la pregunta y selecciona el tipo de respuesta que tendrá.</Text>
      </PageHeader>
      <form onSubmit={handleSubmit}>
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} md={{ span: 14 }} lg={{ span: 16 }}>
            <Card style={{ marginBottom: '1.5rem' }}>
              <section>
                <Title level={3}>Encabezado</Title>
                <div style={{ marginBottom: '1.5rem' }}>
                  <Input
                    size='large'
                    placeholder='Título de la encuesta'
                    name='title'
                    value={data.title}
                    onChange={(ev) => setData({ title: ev.target.value })}
                    style={errors.title ? { borderColor: '#FF4D4F' } : {}}
                  />
                  {errors.title ? <div style={{ color: '#FF4D4F', fontSize: '0.75rem', fontWeight: '600', marginTop: '0.25rem' }}>Olvidaste definir el título de la encuesta</div> : null}
                </div>
                <div>
                  <Input.TextArea
                    autoSize
                    placeholder='Descripción de la encuesta o indicaciones para el encuestado'
                    name='description'
                    value={data.description}
                    onChange={(ev) => setData({ description: ev.target.value })}
                  />
                </div>
              </section>
            </Card>
            <Card>
              <section>
                <Title level={3}>Preguntas</Title>
                <SortableContainer onSortStart={handleSortStart} onSortEnd={handleSortEnd} useDragHandle>
                  {questions.map((item, i) => (
                    <SortableItem
                      key={`item-${i}`}
                      index={i}
                      {...item}
                      position={i + 1}
                      setEditable={(value) => setQuestion({ value: value, field: 'editable' }, i)}
                      deleteItem={() => deleteQuestion(i)}
                      duplicateItem={() => duplicateQuestion(i)}
                      onChange={(value) => setQuestion(value, i)}
                      hasError={errors['item' + i]}
                    />
                  ))}
                </SortableContainer>
                <div style={{ textAlign: 'center' }}>
                  <Button shape='circle' icon={<PlusOutlined />} onClick={() => addQuestion()} />
                </div>
              </section>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }}>
            <Card>
              <section style={{ marginBottom: '1.5rem' }}>
                <Title level={3}>Mostrar encuesta</Title>
                <div style={{ marginBottom: '0.5rem' }}>
                  <Text type='secondary'>Si deseas que la encuesta no se muestre mientras sigues trabajando en ella, desactívala a continuación.</Text>
                </div>
                <Switch checked={data.active} onChange={() => setData({ active: !data.active })} />
              </section>
              <section style={{ marginBottom: '1.75rem' }}>
                <Title level={3}>Segmentar encuesta</Title>
                <div style={{ marginBottom: '0.5rem' }}>
                  <Text type='secondary'>Si deseas que la encuesta se muestre solo en algunos campus, selecciona los campus que participarán en ella.</Text>
                </div>
                <Select {...selectProps} placeholder='Mostrar en todos' />
              </section>
              <section style={{ marginBottom: '1.75rem' }}>
                <Title level={3}>Disponible como plantilla</Title>
                <div style={{ marginBottom: '0.5rem' }}>
                  <Text type='secondary'>Marca la casilla de a continuacion para que esta encuesta este disponible desde el menú "nueva desde".</Text>
                </div>
                <Checkbox checked={data.template} onChange={() => setData({ template: !data.template })}>Guardar plantilla</Checkbox>
              </section>
              <Space size='middle'>
                <Popconfirm
                  title='¿Estás seguro de cancelar？se perderán todos los avances de esta encuesta'
                  okText='Sí'
                  cancelText='No'
                  onConfirm={() => { window.sessionStorage.removeItem('new-survey'); history.replace('/') }}
                >
                  <Button size='large'>Cancelar</Button>
                </Popconfirm>
                <Button type='primary' size='large' onClick={handleSubmit} loading={saving}>Guardar encuesta</Button>
              </Space>
            </Card>
          </Col>
        </Row>
      </form>
    </>
  )
}

const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>)

const SortableItem = sortableElement((props) => <SurveyItem {...props} />)

export default NewSurvey
