import React from 'react'
import style from './ListItem.module.css'

import { Typography, Row, Col, Switch, Badge, Button, Skeleton } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

import setDates from '../../utils/setDates'

const { Title, Text } = Typography

const ItemAdmin = ({ id, title, lastUpdate, active, toggleSurvey, countResults }) => {
  const history = useHistory()

  return (
    <div className={style.container}>
      <Title level={3}><Link to={`/edit/${id}`}>{title}</Link></Title>
      <Row justify='space-between' align='middle'>
        <Col>
          <Badge status={active ? 'success' : 'default'} /><Text type='secondary'>Última modificación {lastUpdate ? setDates(lastUpdate.seconds) : 'NA'}</Text>
        </Col>
        <Col>
          <Switch
            checked={active}
            onChange={(value) => toggleSurvey(id, value)}
          />
        </Col>
      </Row>
      <Row justify='space-between' align='middle' style={{ marginTop: '1rem' }}>
        <Col>
          <Text type='secondary'>{countResults ? countResults > 1 ? `${countResults} encuestas resueltas` : `${countResults} encuesta resuelta` : 'Aún no se han enviado resultados'}</Text>
        </Col>
        <Col>
          <Button type='secondary' onClick={() => history.push(`/results/${id}`)}>Ir a resultados</Button>
        </Col>
      </Row>
    </div>
  )
}

const ItemAlumno = ({ id, title, lastUpdate, completed }) => {
  return (
    <div className={style.container}>
      <Title level={3} type='secondary'>
        {completed
          ? title
          : <Link to={`/survey/${id}`}>{title}</Link>}
      </Title>
      <Row justify='space-between' align='middle'>
        <Col>
          <Badge status='success' /><Text type='secondary'>Publicada {lastUpdate ? setDates(lastUpdate.seconds) : 'NA'}</Text>
        </Col>
        {completed && <Col><Text className={style.status}>Completada</Text><CheckCircleOutlined className={style.icon} /></Col>}
      </Row>
    </div>
  )
}

const ItemLoading = () => {
  return (
    <div className={`${style.container}`}>
      <Skeleton.Input active />
      <Row justify='space-between' align='middle' style={{ marginTop: '0.5rem' }}>
        <Col><Skeleton.Button active size='small' style={{ width: 120 }} /></Col>
        <Col><Skeleton.Button active size='small' style={{ width: 100 }} /></Col>
      </Row>
    </div>
  )
}

export { ItemAdmin, ItemAlumno, ItemLoading }
