import { useContext } from 'react'

import { ProfileContext } from '../context/Profile'

const useProfile = () => {
  const profile = useContext(ProfileContext)

  return profile
}

export default useProfile
