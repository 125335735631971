import { useEffect, useReducer } from 'react'

import firebase from '../lib/firebase'

const initialState = {
  data: [],
  loading: false,
  error: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD':
      return {
        ...state,
        loading: true
      }
    case 'SUCCESS':
      return {
        data: action.payload,
        loading: false,
        error: null
      }
    case 'CHANGE_ACTIVE':
      return {
        ...state,
        data: action.payload
      }
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

const useSurveys = (all) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const toggleSurvey = (id, value) => {
    firebase.firestore().collection('surveys').doc(id).update({
      active: value
    })
      .then(() => {
        const updatedIndex = state.data.findIndex((elem) => elem.id === id)
        const newData = [...state.data]
        newData[updatedIndex].active = value
        dispatch({
          type: 'CHANGE_ACTIVE',
          payload: newData
        })
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          payload: error.message
        })
        console.log(error)
      })
  }

  useEffect(() => {
    dispatch({ type: 'LOAD' })
    const surveysData = []
    const query = () => {
      if (all) {
        return firebase.firestore().collection('surveys').orderBy('lastUpdate', 'desc').get()
      } else {
        return firebase.firestore().collection('surveys').orderBy('lastUpdate', 'desc').limit(3).get()
      }
    }
    query()
      .then((documents) => {
        documents.forEach((doc) => {
          surveysData.push({ id: doc.id, ...doc.data() })
        })
        dispatch({
          type: 'SUCCESS',
          payload: surveysData
        })
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          payload: error.message
        })
        console.log(error)
      })
  }, [])

  return [state, toggleSurvey]
}

export default useSurveys
