import { useState, useEffect } from 'react'

import firebase from '../lib/firebase'

const useCampusData = () => {
  const [campus, setCampus] = useState([])
  const [careers, setCareers] = useState([])
  const sistemas = ['Escolarizado', 'Mixto', 'Modular Nocturno', 'Dominical', 'En línea']
  const cuatrimestres = [
    'Primer cuatrimestre',
    'Segundo cuatrimestre',
    'Tercer cuatrimestre',
    'Cuarto cuatrimestre',
    'Quinto cuatrimestre',
    'Sexto cuatrimestre',
    'Séptimo cuatrimestre',
    'Octavo cuatrimestre',
    'Noveno cuatrimestre',
    'Décimo cuatrimestre'
  ]
  const semestres = [
    'Primer semestre',
    'Segundo semestre',
    'Tercer semestre',
    'Cuarto semestre',
    'Quinto semestre',
    'Sexto semestre',
    'Séptimo semestre',
    'Octavo semestre'
  ]

  useEffect(() => {
    const campusData = []
    firebase.firestore().collection('campus').get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          campusData.push({ id: doc.id, ...doc.data() })
        })
        setCampus(campusData)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const getCareerByCampus = (campusId) => {
    const careersData = []
    firebase.firestore().collection('campus').doc(campusId).collection('licenciaturas').get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          careersData.push({ id: doc.id, ...doc.data() })
        })
        setCareers(careersData)
      })
  }

  return { campus, careers, getCareerByCampus, sistemas, cuatrimestres, semestres }
}

export default useCampusData
