import React, { useState } from 'react'

import { Form, Input, Button, message } from 'antd'
import { Link } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'

const Login = () => {
  const { signIn } = useAuth()
  const [loading, setLoading] = useState(false)

  const handleFinish = ({ email, password }) => {
    setLoading(true)
    signIn(email, password)
      .catch((error) => {
        setLoading(false)
        message.error(error)
      })
  }

  return (
    <>
      <div style={{ marginBottom: '1rem' }}>Inicia sesión para comenzar</div>
      <Form layout='vertical' name='login' onFinish={handleFinish} style={{ textAlign: 'left' }}>
        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              required: true,
              message: 'Necesitas ingresar tu cuenta de correo'
            },
            {
              pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: 'La cuenta no es un email válido'
            }
          ]}
        >
          <Input placeholder='Cuenta de correo electrónico' size='large' type='email' />
        </Form.Item>
        <Form.Item
          label='Contraseña'
          name='password'
          rules={[
            {
              required: true,
              message: 'Necesitas ingresar tu contraseña'
            }
          ]}
        >
          <Input.Password size='large' />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type='primary' htmlType='submit' size='large' loading={loading}>Iniciar sesión</Button>
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Link to='/recovery'>¿Olvidaste tu contraseña?</Link>
        </Form.Item>
      </Form>
      <div style={{ position: 'absolute', right: 0, bottom: '1rem', left: 0, margin: 'auto' }}>¿Aún no te registras? <Link to='/register'>Crea tu cuenta</Link></div>
    </>
  )
}

export default Login
