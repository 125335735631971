import React from 'react'
import style from './Answer.module.css'

import { Typography, Input, Button, Tooltip, Radio, Checkbox, Slider } from 'antd'
import { DeleteOutlined, FrownOutlined, SmileOutlined } from '@ant-design/icons'

const { Text, Paragraph } = Typography

const SurveyItemAnswer = (props) => {
  const { type, options, subQuestion, description, onChange, error } = props

  switch (type) {
    case 'text':
      return <Input placeholder='Texto de la respuesta' value='' readOnly />
    case 'textarea':
      return <Input.TextArea placeholder='Texto de la respuesta' value='' readOnly />
    case 'radio':
      return <MultipleOption type='radio' options={options} onChange={onChange} />
    case 'checkbox':
      return <MultipleOption type='checkbox' options={options} onChange={onChange} />
    case 'select':
      return <MultipleOption type='select' options={options} onChange={onChange} />
    case 'slider':
      return <SliderOption options={options} onChange={onChange} />
    case 'title':
      return <Input.TextArea placeholder='Escribe una descripción para el encabezado' value={description} onChange={(ev) => onChange({ value: ev.target.value, field: 'description' })} />
    case 'section':
      return <Input.TextArea placeholder='Escribe una descripción para la nueva sección' value={description} onChange={(ev) => onChange({ value: ev.target.value, field: 'description' })} />
    case 'condition':
      return <ConditionOption subQuestion={subQuestion} options={options} onChange={onChange} error={error === 'subQuestion'} />
    default:
      return null
  }
}

const MultipleOption = (props) => {
  const options = props.options

  const addOption = () => {
    options.push(`Opción ${options.length + 1}`)
    props.onChange({ value: options, field: 'options' })
  }

  const deleteOption = (index) => {
    options.splice(index, 1)
    props.onChange({ value: options, field: 'options' })
  }

  const modifyOption = (value, index) => {
    options[index] = value
    props.onChange({ value: options, field: 'options' })
  }

  const renderControl = (index) => {
    switch (props.type) {
      case 'radio':
        return <Radio checked={false} />
      case 'checkbox':
        return <Checkbox checked={false} />
      case 'select':
        return <Text type='secondary'>{index + 1}. </Text>
      default:
        return null
    }
  }

  return (
    <div>
      {options.map((option, i) => (
        <div className={style.option} key={`radio-${i}`}>
          <div style={{ width: '24px', marginRight: '0.5rem' }}>
            {renderControl(i)}
          </div>
          <Paragraph editable={{ tooltip: 'Editar', onChange: (value) => modifyOption(value, i) }} style={{ flex: '1' }}>{option}</Paragraph>
          <Tooltip title='Eliminar'>
            <Button shape='circle' type='text' onClick={() => deleteOption(i)} icon={<DeleteOutlined />} />
          </Tooltip>
        </div>
      ))}
      <div className={style.option}>
        <div style={{ width: '24px', marginRight: '0.5rem' }}>
          {renderControl(options.length)}
        </div>
        <Text type='secondary' style={{ cursor: 'pointer' }} onClick={addOption}>Agregar otra opción</Text>
      </div>
    </div>
  )
}

const SliderOption = (props) => {
  const options = props.options ? [].concat(props.options) : ['Aspecto a evaluar 1']

  const addOption = () => {
    options.push(`Aspecto a evaluar ${options.length + 1}`)
    props.onChange({ value: options, field: 'options' })
  }

  const deleteOption = (index) => {
    options.splice(index, 1)
    props.onChange({ value: options, field: 'options' })
  }

  const modifyOption = (value, index) => {
    options[index] = value
    props.onChange({ value: options, field: 'options' })
  }

  return (
    <div>
      {options.map((option, i) => (
        <div className={style.option} key={`radio-${i}`}>
          <div className={style.slider}>
            <FrownOutlined />
            <Slider min={1} max={5} step={1} value={3} style={{ flex: 1 }} tooltipVisible={false} />
            <SmileOutlined />
          </div>
          <Paragraph editable={{ tooltip: 'Editar', onChange: (value) => modifyOption(value, i) }} style={{ flex: '1' }}>{option}</Paragraph>
          <Tooltip title='Eliminar'>
            <Button shape='circle' type='text' onClick={() => deleteOption(i)} icon={<DeleteOutlined />} />
          </Tooltip>
        </div>
      ))}
      <div className={style.option}>
        <div className={style.slider}>
          <FrownOutlined />
          <Slider min={1} max={5} step={1} value={3} style={{ flex: 1 }} tooltipVisible={false} />
          <SmileOutlined />
        </div>
        <Text type='secondary' style={{ cursor: 'pointer' }} onClick={addOption}>Agregar otro aspecto</Text>
      </div>
    </div>
  )
}

const ConditionOption = ({ options, subQuestion, onChange, error }) => {
  const modifyOption = (value, index) => {
    options[index] = value
    onChange({ value: options, field: 'options' })
  }

  const modifySubQuestion = (ev) => {
    onChange({ value: ev.target.value, field: 'subQuestion' })
  }

  return (
    <div>
      <div className={style.row}>
        <Radio checked={false} style={{ marginRight: '1rem' }} />
        <Paragraph editable={{ tooltip: 'Editar', onChange: (value) => modifyOption(value, 0) }}>{options[0]}</Paragraph>
        <Text type='secondary' style={{ marginLeft: '1rem' }}><small>Esta opción habilita la pregunta condicional</small></Text>
      </div>
      <div className={style.row}>
        <Radio checked={false} style={{ marginRight: '1rem' }} />
        <Paragraph editable={{ tooltip: 'Editar', onChange: (value) => modifyOption(value, 1) }}>{options[1]}</Paragraph>
      </div>
      <Input
        placeholder='Escribe la pregunta que se mostrará cuando se elija la primera opción'
        value={subQuestion}
        onChange={modifySubQuestion}
        style={error ? { borderColor: '#FF4D4F', marginTop: '1.5rem' } : { marginTop: '1.5rem' }}
      />
    </div>
  )
}

export default SurveyItemAnswer
