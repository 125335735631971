import React, { useState, useEffect } from 'react'

import { Card, Row, Col, Typography, Checkbox, Switch, Select, Button, Space, Popconfirm, message, Input, Skeleton } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { sortableContainer, sortableElement } from 'react-sortable-hoc'
import { useHistory, useParams } from 'react-router-dom'

import PageHeader from '../layout/PageHeader'
import SurveyItem from '../modules/SurveyItem'

import useSurveyAsAdmin from '../../hooks/useSurveyAsAdmin'
import useCampusData from '../../hooks/useCampusData'

const { Title, Text } = Typography

const EditSurvey = () => {
  const { id } = useParams()
  const { state, setData, addQuestion, sortQuestion, setQuestion, deleteQuestion, duplicateQuestion, editSurvey, deleteSurvey } = useSurveyAsAdmin(id)
  const { data, questions, loading, saving, deleting, error } = state
  const { campus } = useCampusData()
  const [errors, setErrors] = useState({})
  const history = useHistory()

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%'
    },
    value: data.onlyCampus,
    options: campus.map((item) => ({ label: item.name, value: item.name })),
    onChange: (value) => {
      setData({ onlyCampus: value })
    },
    maxTagCount: 'responsive'
  }

  const handleSubmit = () => {
    validateData()
      .then(() => {
        return editSurvey(id)
      })
      .then(() => {
        message.success('La encuesta se ha modificado correctamente.', () => history.push('/'))
      })
      .catch((error) => {
        message.error(error.message)
      })
  }

  const handleSortStart = () => {
    document.body.style.cursor = 'ns-resize'
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    sortQuestion({ oldIndex, newIndex })
    document.body.style.cursor = 'default'
  }

  const validateData = () => {
    return new Promise((resolve, reject) => {
      let errorsObject = {}
      if (data.title.trim() === '') {
        errorsObject = { ...errorsObject, title: true }
      }
      questions.forEach((item, i) => {
        if (item.question.trim() === '') {
          errorsObject = { ...errorsObject, ['item' + i]: true }
          return
        }
        if (item.type === 'radio' || item.type === 'checkbox' || item.type === 'select' || item.type === 'slider') {
          if (item.options === null) {
            errorsObject = { ...errorsObject, ['item' + i]: true }
          } else if (item.options.length < 1) {
            errorsObject = { ...errorsObject, ['item' + i]: true }
          }
        } else if (item.type === 'condition') {
          if (item.subQuestion === null) {
            errorsObject = { ...errorsObject, ['item' + i]: true }
          } else if (item.subQuestion.trim() === '') {
            errorsObject = { ...errorsObject, ['item' + i]: true }
          }
        } else if (item.type === 'section') {
          if (!questions[i + 1] || questions[i + 1]?.type === 'section') {
            errorsObject = { ...errorsObject, ['item' + i]: true }
          }
        }
      })
      if (Object.entries(errorsObject).length === 0) {
        setErrors({})
        resolve()
      } else {
        setErrors(errorsObject)
        reject(new Error('Hay algunos errores que hay que solucionar antes de guardar la encuesta.'))
      }
    })
  }

  const handleConfirm = () => {
    deleteSurvey(id)
      .then(() => {
        message.success('La encuesta se ha eliminado correctamente.', () => history.replace('/'))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (error) {
      message.error(error)
    }
  }, [error])

  return (
    <>
      <PageHeader
        title='Editar encuesta'
        subtitle='Administrador'
        back='Inicio'
      >
        <Text type='secondary'>Modifica los siguientes campos para editar una encuesta. Tus cambios se verán reflejados una vez que guardes la encuesta.</Text>
      </PageHeader>
      <form onSubmit={handleSubmit}>
        <Row gutter={[24, 24]}>
          <Col xs={{ span: 24 }} md={{ span: 14 }} lg={{ span: 16 }}>
            <Card style={{ marginBottom: '1.5rem' }}>
              <section>
                <Title level={3}>Encabezado</Title>
                <div style={{ marginBottom: '1.5rem' }}>
                  {loading
                    ? <Skeleton.Input size='large' active />
                    : <Input
                        size='large'
                        placeholder='Título de la encuesta'
                        name='title'
                        value={data.title}
                        onChange={(ev) => setData({ title: ev.target.value })}
                        style={errors.title ? { borderColor: '#FF4D4F' } : {}}
                      />}
                  {errors.title ? <div style={{ color: '#FF4D4F', fontSize: '0.75rem', fontWeight: '600', marginTop: '0.25rem' }}>Olvidaste definir el título de la encuesta</div> : null}
                </div>
                <div>
                  {loading
                    ? <Skeleton.Input active />
                    : <Input.TextArea
                        autoSize
                        placeholder='Descripción de la encuesta o indicaciones para el encuestado'
                        name='description'
                        value={data.description}
                        onChange={(ev) => setData({ description: ev.target.value })}
                      />}
                </div>
              </section>
            </Card>
            <Card>
              <section>
                <Title level={3}>Preguntas</Title>
                <SortableContainer onSortStart={handleSortStart} onSortEnd={handleSortEnd} useDragHandle>
                  {!loading && questions.map((item, i) => (
                    <SortableItem
                      key={`item-${i}`}
                      index={i}
                      {...item}
                      position={i + 1}
                      setEditable={(value) => setQuestion({ value: value, field: 'editable' }, i)}
                      deleteItem={() => deleteQuestion(i)}
                      duplicateItem={() => duplicateQuestion(i)}
                      onChange={(value) => setQuestion(value, i)}
                      hasError={errors['item' + i]}
                    />
                  ))}
                </SortableContainer>
                <div style={{ textAlign: 'center' }}>
                  <Button shape='circle' icon={<PlusOutlined />} onClick={() => addQuestion()} />
                </div>
              </section>
            </Card>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 10 }} lg={{ span: 8 }}>
            <Card>
              <section style={{ marginBottom: '1.5rem' }}>
                <Title level={3}>Mostrar encuesta</Title>
                <div style={{ marginBottom: '0.5rem' }}>
                  <Text type='secondary'>Si deseas que la encuesta no se muestre mientras sigues trabajando en ella, desactívala a continuación.</Text>
                </div>
                {loading ? <Skeleton.Button shape='round' size='small' active /> : <Switch checked={data.active} onChange={() => setData({ active: !data.active })} />}
              </section>
              <section style={{ marginBottom: '1.75rem' }}>
                <Title level={3}>Segmentar encuesta</Title>
                <div style={{ marginBottom: '0.5rem' }}>
                  <Text type='secondary'>Si deseas que la encuesta se muestre solo en algunos campus, selecciona los campus que participarán en ella.</Text>
                </div>
                {loading ? <Skeleton.Input active /> : <Select {...selectProps} placeholder='Mostrar en todos' />}
              </section>
              <section style={{ marginBottom: '1.75rem' }}>
                <Title level={3}>Disponible como plantilla</Title>
                <div style={{ marginBottom: '0.5rem' }}>
                  <Text type='secondary'>Marca la casilla de a continuacion para que esta encuesta este disponible desde el menú "nueva desde".</Text>
                </div>
                {loading ? <Skeleton.Input size='small' style={{ width: 126 }} active /> : <Checkbox checked={data.template} onChange={() => setData({ template: !data.template })}>Guardar plantilla</Checkbox>}
              </section>
              <Space size='middle' style={{ marginBottom: '1.5rem' }}>
                <Popconfirm
                  title='¿Estás seguro de cancelar？se perderán todas las modificaciones de esta encuesta'
                  okText='Sí'
                  cancelText='No'
                  onConfirm={() => history.replace('/')}
                >
                  <Button size='large'>Cancelar</Button>
                </Popconfirm>
                <Button type='primary' size='large' onClick={handleSubmit} loading={saving}>Guardar encuesta</Button>
              </Space>
              <Popconfirm
                title='¿Estás seguro de querer eliminar esta encuesta? una vez eliminada no se puede recuperar'
                okText='Sí'
                cancelText='No'
                onConfirm={handleConfirm}
              >
                <Button type='text' danger loading={deleting}>Eliminar encuesta</Button>
              </Popconfirm>
            </Card>
          </Col>
        </Row>
      </form>
    </>
  )
}

const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>)

const SortableItem = sortableElement((props) => <SurveyItem {...props} />)

export default EditSurvey
