import React, { useState } from 'react'
import style from './SurveyItem.module.css'

import { Typography, Input, Select, Row, Col, Card, Button, Space, Switch, Tooltip } from 'antd'
import { MenuOutlined, CheckCircleOutlined, DeleteOutlined, CopyOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { sortableHandle } from 'react-sortable-hoc'

import Answer from './SurveyItem/Answer'
import Preview from './SurveyItem/Preview'

const { Text } = Typography
const { Option } = Select

const SurveyItem = (props) => {
  const [error, setError] = useState(null)

  const { question, type, options, mandatory, subQuestion, description, position, editable, setEditable, deleteItem, duplicateItem, onChange, hasError } = props

  const validateData = () => {
    if (question.trim() === '') {
      return setError('question')
    }
    if (type === 'radio' || type === 'checkbox' || type === 'select' || type === 'slider') {
      if (options === null) {
        return setError('options')
      }
      if (options.length < 1) {
        return setError('options')
      }
    }
    if (type === 'condition') {
      if (subQuestion === null) {
        return setError('subQuestion')
      }
      if (subQuestion.trim() === '') {
        return setError('subQuestion')
      }
    }
    setEditable(false)
  }

  return (
    <Card
      className={editable ? style.editable : style.normal}
      onClick={() => !editable && setEditable(true)}
      style={hasError ? { outline: '1px solid #FF4D4F' } : {}}
    >
      <DragHandle />
      {editable
        ? (
          <>
            <Row gutter={{ xs: 16, sm: 16, md: 16, lg: 24 }}>
              <Col span={16}>
                <Input
                  placeholder={
                    type !== 'section' && type !== 'title'
                      ? 'Escribe la pregunta'
                      : type === 'section'
                        ? 'Escribe el título de la sección'
                        : 'Escribe el encabezado'
                  }
                  value={question}
                  onChange={(ev) => { onChange({ value: ev.target.value, field: 'question' }); setError(null) }}
                  style={error === 'question' ? { borderColor: '#FF4D4F' } : {}}
                />
                {error === 'question' && type !== 'section' && type !== 'title'
                  ? <div className={style.error}>No olvides definir la pregunta</div>
                  : error === 'question' && type === 'section'
                    ? <div className={style.error}>No olvides definir el título de la sección</div>
                    : error === 'question' && type === 'title'
                      ? <div className={style.error}>No olvides definir el encabezado</div>
                      : null}
              </Col>
              <Col span={8}>
                <Select value={type} onChange={(value) => { onChange({ value: value, field: 'type' }); setError(null) }} style={{ width: '100%' }}>
                  <Option value='text'>Respuesta breve</Option>
                  <Option value='textarea'>Respuesta en párrafo</Option>
                  <Option value='radio'>Opción múltiple</Option>
                  <Option value='checkbox'>Casilla de verificación</Option>
                  <Option value='select'>Lista desplegable</Option>
                  <Option value='condition'>Respuesta condicional</Option>
                  <Option value='title'>Título</Option>
                  <Option value='section'>Sección</Option>
                </Select>
              </Col>
            </Row>
            <div style={{ marginTop: '1.5rem' }}>
              <Answer type={type} options={options} description={description} subQuestion={subQuestion} onChange={(value) => { onChange(value); setError(null) }} error={error} />
              {error === 'options' ? <div className={style.error}>Necesitas agregar una o más opciones de respuesta</div> : null}
              {error === 'subQuestion' ? <div className={style.error}>No olvides definir la pregunta condicional</div> : null}
            </div>
            <div className={style.footer}>
              {(type !== 'section' && type !== 'title')
                ? (
                  <Space size='small'>
                    <Switch checked={mandatory} onChange={() => onChange({ value: !mandatory, field: 'mandatory' })} />
                    <Text type='secondary'>Obligatoria</Text>
                  </Space>)
                : <div> </div>}
              <Space size='middle'>
                <Tooltip title='Eliminar'>
                  <Button shape='circle' onClick={deleteItem} icon={<DeleteOutlined />} />
                </Tooltip>
                <Tooltip title='Duplicar'>
                  <Button shape='circle' onClick={duplicateItem} icon={<CopyOutlined />} />
                </Tooltip>
                <Tooltip title='Terminar'>
                  <Button shape='circle' onClick={validateData} icon={<CheckCircleOutlined />} />
                </Tooltip>
              </Space>
            </div>
            {hasError && <div style={{ marginTop: '1rem', fontSize: '0.875rem', color: '#FF4D4F' }}><ExclamationCircleOutlined /> Termina de editar para validar tus campos.</div>}
          </>)
        : (
          <>
            <div className={`${style.question} ${type === 'section' && style.section} ${type === 'title' && style.title} ${mandatory ? style.mandatory : ''}`}>
              <Text strong>{`${position}) ${question}`}</Text>
              {description && <div style={{ marginTop: '0.5rem' }}><Text type='secondary' className={style.description}>{description}</Text></div>}
            </div>
            <div className={style.answer}>
              <Preview type={type} options={options} subQuestion={subQuestion} />
            </div>
          </>)}
    </Card>
  )
}

const DragHandle = sortableHandle(() => (
  <div className={style.draggable}>
    <MenuOutlined />
  </div>
))

export default SurveyItem
