import React from 'react'
import style from './Log.module.css'

import { Skeleton, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'

import useLogs from '../../hooks/useLogs'
import setDates from '../../utils/setDates'

const { Text, Paragraph } = Typography

const Log = () => {
  const [state] = useLogs()
  const { data, loading, error } = state

  return (
    <div className={style.container}>
      {loading
        ? (
          <>
            <div className={style.item}>
              <Skeleton title={false} paragraph={{ rows: 3 }} active />
            </div>
            <div className={style.item}>
              <Skeleton title={false} paragraph={{ rows: 3 }} active />
            </div>
            <div className={style.item}>
              <Skeleton title={false} paragraph={{ rows: 3 }} active />
            </div>
            <div className={style.item}>
              <Skeleton title={false} paragraph={{ rows: 3 }} active />
            </div>
          </>
          )
        : error
          ? (
            <div className={style.content}>
              <div>
                <CloseCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.12)', fontSize: '4rem', marginBottom: '1.5rem' }} />
                <Paragraph>{error}</Paragraph>
                <Paragraph type='secondary'>Ocurrió un error al cargar la actividad reciente. Refresca la página para reintentarlo.</Paragraph>
              </div>
            </div>
            )
          : data.length
            ? data.map((item, i) => <Item key={'log' + i} {...item} />)
            : (
              <div className={style.content}>
                <div>
                  <Paragraph>No hay actividad para mostrar</Paragraph>
                  <Paragraph type='secondary'>En este espacio se mostrará la actividad de los usuarios en tiempo real.</Paragraph>
                </div>
              </div>
              )}
    </div>
  )
}

const Item = ({ user, title, sendedAt }) => (
  <div className={style.item}>
    <div>
      <Text strong>{user}</Text> <Text type='secondary'>- {setDates(sendedAt?.seconds)}</Text>
    </div>
    <div>
      <Text>{`Ha completado la encuesta "${title}".`}</Text>
    </div>
  </div>
)

export default Log
