import { useEffect, useReducer } from 'react'

import firebase from '../lib/firebase'

import useAuth from './useAuth'
import useProfile from './useProfile'

const initialState = {
  data: {},
  loading: false,
  error: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD':
      return {
        ...state,
        loading: true
      }
    case 'SUCCESS':
      return {
        data: action.payload,
        loading: false,
        error: null
      }
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case 'COMPLETED':
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

const useSurvey = (id) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { user } = useAuth()
  const { profile } = useProfile()

  const submitSurvey = (answers) => {
    return new Promise((resolve, reject) => {
      const currentUser = firebase.auth().currentUser
      const sections = Object.keys(answers)
      let allAnswers = {}
      sections.forEach((section) => {
        allAnswers = { ...allAnswers, ...answers[section] }
      })
      firebase.firestore().collection('surveys').doc(id).collection('answers').doc(currentUser.uid).set({
        answers: allAnswers,
        campus: profile.campus,
        carrera: profile.carrera,
        code: profile.code,
        grado: profile.grado,
        email: profile.email,
        name: profile.firstName + ' ' + profile.lastName + ' ' + profile.mLastName,
        sistema: profile.sistema,
        tel: profile.tel,
        sendedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject(error.message)
        })
    })
  }

  useEffect(() => {
    dispatch({ type: 'LOAD' })
    let data = {}
    firebase.firestore().collection('surveys').doc(id).get()
      .then((doc) => {
        if (doc.exists) {
          data = { ...doc.data() }
          return firebase.firestore().collection('surveys').doc(doc.id).collection('answers').doc(user.uid).get()
        } else {
          dispatch({
            type: 'ERROR',
            payload: 'No se encontró la encuesta, inténtalo mas tarde o ponte en contacto con tu coordinador de vinculación.'
          })
          return null
        }
      })
      .then((doc) => {
        if (doc) {
          if (doc.exists) {
            dispatch({
              type: 'COMPLETED',
              payload: 'Ya has completado esta encuesta. Tus respuestas fueron enviadas y procesadas con éxito por lo que ya no te es posible volver a llenarla.'
            })
            return null
          } else {
            return firebase.firestore().collection('surveys').doc(id).collection('questions').get()
          }
        }
        return null
      })
      .then((documents) => {
        if (documents) {
          data.questions = []
          documents.forEach((doc) => {
            data.questions.push({
              id: doc.id,
              ...doc.data()
            })
          })
          data.questions.sort((a, b) => a.order > b.order ? 1 : b.order > a.order ? -1 : 0)
          let index = 1
          data.questions.forEach((question, i) => {
            if (question.type !== 'title') {
              data.questions[i].index = index
              index++
            }
          })
          if (data.sections) {
            const sections = Object.keys(data.sections)
            sections.forEach((section) => {
              const sectionQuestions = []
              data.sections[section].questions.forEach((sectionQuestion) => {
                sectionQuestions.push(data.questions.find((question) => question.id === sectionQuestion))
              })
              data.sections[section].questions = sectionQuestions
            })
          } else {
            data.sections = {
              1: {
                questions: data.questions
              }
            }
          }
          dispatch({
            type: 'SUCCESS',
            payload: data
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          payload: error.message
        })
        console.log(error)
      })
  }, [])

  return [state, submitSurvey]
}

export default useSurvey
