import React, { useEffect } from 'react'

import { BrowserRouter as Router, Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom'
import { Button, Result } from 'antd'

import AuthLayout from './layout/AuthLayout'
import Layout from './layout/Layout'
import Login from './routes/Login'
import Register from './routes/Register'
import PasswordRecovery from './routes/PasswordRecovery'
import Dashboard from './routes/Dashboard'
import Archive from './routes/Archive'
import EmailChange from './routes/EmailChange'
import PasswordChange from './routes/PasswordChange'
import Profile from './routes/Profile'
import CreateAccount from './routes/CreateAccount'
import NewSurvey from './routes/NewSurvey'
import NewSurveyTemplate from './routes/NewSurveyTemplate'
import EditSurvey from './routes/EditSurvey'
import Results from './routes/Results'
import Survey from './routes/Survey'
import { ReactComponent as Glyph } from '../images/adorno.svg'

import { AuthProvider, AuthConsumer } from '../context/Auth'
import { ProfileProvider } from '../context/Profile'

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
        <Switch>
          <AuthRoute exact path='/login'>
            <Login />
          </AuthRoute>
          <AuthRoute exact path='/register'>
            <Register />
          </AuthRoute>
          <AuthRoute exact path='/recovery'>
            <PasswordRecovery />
          </AuthRoute>
          <CheckRoute exact path='/create-account'>
            <CreateAccount />
          </CheckRoute>
          <PrivateRoute exact path='/archive' role='admin'>
            <Archive />
          </PrivateRoute>
          <PrivateRoute exact path='/results/:id' role='admin'>
            <Results />
          </PrivateRoute>
          <PrivateRoute exact path='/new' role='admin'>
            <NewSurvey />
          </PrivateRoute>
          <PrivateRoute exact path='/new/:id' role='admin'>
            <NewSurveyTemplate />
          </PrivateRoute>
          <PrivateRoute exact path='/edit/:id' role='admin'>
            <EditSurvey />
          </PrivateRoute>
          <PrivateRoute exact path='/survey/:id'>
            <Survey />
          </PrivateRoute>
          <PrivateRoute exact path='/account/profile'>
            <Profile />
          </PrivateRoute>
          <PrivateRoute exact path='/account/email'>
            <EmailChange />
          </PrivateRoute>
          <PrivateRoute exact path='/account/password'>
            <PasswordChange />
          </PrivateRoute>
          <PrivateRoute exact path='/'>
            <Dashboard />
          </PrivateRoute>
        </Switch>
      </AuthProvider>
    </Router>
  )
}

const AuthRoute = ({ children, ...rest }) => (
  <AuthConsumer>
    {({ user }) => {
      if (!user) {
        return (
          <Route {...rest}>
            <AuthLayout>{children}</AuthLayout>
          </Route>
        )
      }
      return <Redirect to='/' />
    }}
  </AuthConsumer>
)

const PrivateRoute = ({ children, role, ...rest }) => {
  const history = useHistory()

  return (
    <AuthConsumer>
      {({ user }) => {
        if (user) {
          if (!user.displayName) {
            return (
              <Redirect to='/create-account' />
            )
          }
          if (role === 'admin') {
            if (user.admin) {
              return (
                <Route {...rest}>
                  <ProfileProvider>
                    <Layout>{children}</Layout>
                  </ProfileProvider>
                </Route>
              )
            }
            return (
              <Route {...rest}>
                <Layout>
                  <Result
                    title='Error 403'
                    subTitle='Lo sentimos, no estás autorizado para acceder a esta página.'
                    icon={<Glyph style={{ width: '180px', height: 'auto', fill: 'rgba(0, 0, 0, 0.24)', margin: '2.5rem auto' }} />}
                    extra={<Button type='primary' key='home' onClick={() => history.push('/')}>Regresar a inicio</Button>}
                  />
                </Layout>
              </Route>
            )
          }
          return (
            <Route {...rest}>
              <ProfileProvider>
                <Layout>{children}</Layout>
              </ProfileProvider>
            </Route>
          )
        }
        return <Redirect to='/login' />
      }}
    </AuthConsumer>
  )
}

const CheckRoute = ({ children, ...rest }) => (
  <AuthConsumer>
    {({ user }) => {
      if (user) {
        if (user.displayName) {
          return (
            <Redirect to='/' />
          )
        }
        return (
          <Route {...rest}>
            <ProfileProvider>
              {children}
            </ProfileProvider>
          </Route>
        )
      }
      return <Redirect to='/login' />
    }}
  </AuthConsumer>
)

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default App
