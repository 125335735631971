import React from 'react'
import style from './Preview.module.css'

import { Typography, Input, Radio, Checkbox, Slider } from 'antd'
import { FrownOutlined, SmileOutlined } from '@ant-design/icons'

const { Text } = Typography

const Preview = (props) => {
  const { type, options, subQuestion } = props

  switch (type) {
    case 'text':
      return <Input placeholder='Respuesta breve' value='' readOnly />
    case 'textarea':
      return <Input.TextArea placeholder='Respuesta en párrafo' value='' readOnly />
    case 'radio':
      return (
        <>
          {options && options.map((option, i) => (
            <div className={style.option} key={`preview-${i}`}>
              <Radio checked={false} style={{ width: '24px', marginRight: '0.5rem' }} />
              <Text type='secondary'>{option}</Text>
            </div>
          ))}
        </>
      )
    case 'checkbox':
      return (
        <>
          {options && options.map((option, i) => (
            <div className={style.option} key={`preview-${i}`}>
              <Checkbox checked={false} style={{ width: '24px', marginRight: '0.5rem' }} />
              <Text type='secondary'>{option}</Text>
            </div>
          ))}
        </>
      )
    case 'select':
      return (
        <>
          {options && options.map((option, i) => (
            <div className={style.option} key={`preview-${i}`}>
              <Text type='secondary' style={{ width: '24px', marginRight: '0.5rem' }}>{i + 1}. </Text>
              <Text type='secondary'>{option}</Text>
            </div>
          ))}
        </>
      )
    case 'slider':
      return (
        <>
          {options && options.map((option, i) => (
            <div className={style.option} key={`preview-${i}`}>
              <div className={style.slider}>
                <FrownOutlined />
                <Slider min={1} max={5} step={1} value={3} style={{ flex: 1 }} tooltipVisible={false} />
                <SmileOutlined />
              </div>
              <Text type='secondary'>{option}</Text>
            </div>
          ))}
        </>
      )
    case 'condition':
      return (
        <>
          <Radio checked={false}>{options[0]}</Radio>
          <Radio checked={false}>{options[1]}</Radio>
          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}><Text strong type='secondary'>{subQuestion}</Text></div>
          <Input placeholder='Respuesta breve' value='' readOnly />
        </>
      )
    default:
      return null
  }
}

export default Preview
