import React, { useEffect } from 'react'

import { Card, Typography, Button, Table, Skeleton, message } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'

import PageHeader from '../layout/PageHeader'

import useResults from '../../hooks/useResults'

const { Title, Text } = Typography

const Results = () => {
  const { id } = useParams()
  const { state, exportResults } = useResults(id)
  const { title, countResults, columns, data, loading, exporting, error } = state

  useEffect(() => {
    if (error) {
      message.error(error)
    }
  }, [error])

  return (
    <>
      <PageHeader
        title='Resultados de encuesta'
        subtitle='Administrador'
        back='Inicio'
        extra={[
          <Button
            key='1'
            icon={<ExportOutlined />}
            size='large'
            type='primary'
            loading={exporting}
            onClick={exportResults}
          >
            Exportar resultados
          </Button>
        ]}
      >
        <Text type='secondary'>Consulta en tiempo real los resultados de la encuesta. Exporta los resultados para descargar un archivo de excel.</Text>
      </PageHeader>
      <Card style={{ marginBottom: '1.5rem' }}>
        {loading
          ? (
            <>
              <Skeleton.Input size='large' style={{ marginBottom: '1.25rem' }} active />
              <Skeleton active paragraph={{ rows: 1 }} title={false} />
            </>
            )
          : (
            <>
              <Title level={2} style={{ marginBottom: '1.5rem' }}>{title}</Title>
              <Text type='secondary'>{countResults ? countResults > 1 ? `${countResults} encuestas resueltas` : `${countResults} encuesta resuelta` : 'Aún no se han enviado resultados'}</Text>
            </>
            )}
      </Card>
      <Card>
        <Title level={3} style={{ marginBottom: '1.5rem' }}>Últimas respuestas</Title>
        {!loading && (
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ x: 'max-content' }}
          />
        )}
      </Card>
    </>
  )
}

export default Results
