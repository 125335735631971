import React from 'react'
import style from './PageHeader.module.css'

import { PageHeader as Header } from 'antd'
import { useHistory } from 'react-router-dom'

const PageHeader = ({ title, subtitle, children, back, extra }) => {
  const history = useHistory()

  return (
    <Header
      className={style.container}
      title={title}
      subTitle={subtitle}
      onBack={back !== false ? () => history.goBack() : null}
      extra={extra}
    >
      {children}
    </Header>
  )
}

export default PageHeader
