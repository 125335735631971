import { useEffect, useReducer } from 'react'
import axios from 'axios'
import { saveAs } from 'file-saver'

import firebase from '../lib/firebase'

const initialState = {
  title: '',
  totalResults: 0,
  columns: [],
  data: [],
  loading: false,
  exporting: false,
  error: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD':
      return {
        ...state,
        loading: true
      }
    case 'LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case 'EXPORT':
      return {
        ...state,
        exporting: true
      }
    case 'EXPORT_SUCCESS':
      return {
        ...state,
        exporting: false
      }
    case 'ERROR':
      return {
        ...state,
        loading: false,
        exporting: false,
        error: action.payload
      }
    default:
      return state
  }
}

const useResults = (id) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const exportResults = () => {
    return new Promise((resolve, reject) => {
      dispatch({ type: 'EXPORT' })
      let functionUrl = 'https://us-central1-controldecalidad-uva.cloudfunctions.net/exportResultsV2'
      if (process.env.NODE_ENV !== 'production') {
        functionUrl = 'http://localhost:5001/controldecalidad-uva/us-central1/exportResultsV2'
      }
      firebase.auth().currentUser.getIdToken(true)
        .then((token) => {
          return axios({
            method: 'get',
            url: functionUrl,
            responseType: 'blob',
            headers: {
              Authorization: token
            },
            params: {
              id: id
            }
          })
        })
        .then((res) => {
          const file = res.data
          const filename = state.title ? `${state.title.replace(/\s+/g, '_').slice(0, 250)}.xlsx` : 'resultados.xlsx'
          return saveAs(file, filename)
        })
        .then(() => {
          dispatch({ type: 'EXPORT_SUCCESS' })
          resolve()
        })
        .catch((error) => {
          dispatch({
            type: 'ERROR',
            payload: error.message
          })
          reject(error)
        })
    })
  }

  useEffect(() => {
    dispatch({ type: 'LOAD' })
    const newState = {}
    const dbRef = firebase.firestore().collection('surveys').doc(id)
    dbRef.get()
      .then((doc) => {
        if (doc.exists) {
          newState.title = doc.data().title
          newState.countResults = doc.data().countResults
        }
        return dbRef.collection('questions').get()
      })
      .then((docs) => {
        const questions = []
        docs.forEach((doc) => {
          if (doc.data().type !== 'title') {
            questions.push({ id: doc.id, question: doc.data().question, order: doc.data().order })
          }
        })
        if (questions.length) questions.sort((a, b) => a.order > b.order ? 1 : b.order > a.order ? -1 : 0)
        newState.columns = [
          {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            fixed: 'left'
          },
          {
            title: 'Matrícula',
            dataIndex: 'id',
            key: 'id',
            width: 100
          },
          {
            title: 'Campus',
            dataIndex: 'campus',
            key: 'campus',
            width: 160
          },
          {
            title: 'Carrera',
            dataIndex: 'carrera',
            key: 'carrera',
            width: 160
          }
        ]
        questions.forEach((question) => newState.columns.push({
          title: question.question,
          dataIndex: question.id,
          key: question.id,
          width: 400
        }))
        return dbRef.collection('answers').orderBy('sendedAt', 'desc').limit(3).get()
      })
      .then((docs) => {
        const answers = []
        docs.forEach((doc) => {
          answers.push({ id: doc.id, ...doc.data() })
        })
        newState.data = []
        answers.forEach((answer, i) => {
          const row = {
            key: i,
            name: answer.name,
            id: answer.code,
            campus: answer.campus,
            carrera: answer.carrera
          }
          Object.keys(answer.answers).forEach((key) => {
            row[key] = answer.answers[key].value
          })
          newState.data.push(row)
        })
        dispatch({
          type: 'LOAD_SUCCESS',
          payload: newState
        })
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          payload: error.message
        })
      })
  }, [])

  return { state, exportResults }
}

export default useResults
