import { useEffect, useReducer } from 'react'
import arrayMove from 'array-move'

import firebase from '../lib/firebase'

const initialState = {
  data: {
    title: '',
    description: '',
    active: true,
    template: false,
    onlyCampus: [],
    segmented: false
  },
  questions: [{
    question: '',
    type: 'text',
    options: null,
    mandatory: true,
    subQuestion: null,
    description: null,
    editable: true
  }],
  loading: false,
  saving: false,
  deleting: false,
  error: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD':
      return {
        ...state,
        loading: true
      }
    case 'LOAD_SUCCESS':
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case 'SET_DATA':
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      }
    case 'SET_QUESTIONS':
      return {
        ...state,
        questions: action.payload
      }
    case 'SAVE':
      return {
        ...state,
        saving: true
      }
    case 'SAVE_SUCCESS':
      return {
        ...state,
        saving: false
      }
    case 'DELETE':
      return {
        ...state,
        deleting: true
      }
    case 'DELETE_SUCCESS':
      return {
        ...state,
        deleting: false
      }
    case 'ERROR':
      return {
        ...state,
        loading: false,
        saving: false,
        deleting: false,
        error: action.payload
      }
    default:
      return state
  }
}

const deletedQuestions = []

const useSurveyAsAdmin = (id, localInitialState, isTemplate) => {
  const [state, dispatch] = useReducer(reducer, localInitialState ? { ...initialState, ...localInitialState } : initialState)

  const setData = (data) => {
    dispatch({
      type: 'SET_DATA',
      payload: data
    })
  }

  const addQuestion = () => {
    const questions = [...state.questions, {
      question: '',
      type: 'text',
      options: null,
      mandatory: true,
      subQuestion: null,
      description: null,
      editable: true
    }]
    dispatch({
      type: 'SET_QUESTIONS',
      payload: questions
    })
  }

  const sortQuestion = ({ oldIndex, newIndex }) => {
    dispatch({
      type: 'SET_QUESTIONS',
      payload: arrayMove(state.questions, oldIndex, newIndex)
    })
  }

  const setQuestion = ({ value, field }, index) => {
    const questions = [...state.questions]
    if (field === 'type') {
      if (value === 'radio' || value === 'checkbox' || value === 'select' || value === 'slider') {
        questions[index].description = null
        questions[index].options = ['Opción 1']
        questions[index].subQuestion = null
      } else if (value === 'condition') {
        questions[index].description = null
        questions[index].options = ['Si', 'No']
        questions[index].subQuestion = ''
      } else if (value === 'section' || value === 'title') {
        questions[index].description = null
        questions[index].options = null
        questions[index].subQuestion = null
        questions[index].mandatory = false
      }
    }
    questions[index] = {
      ...questions[index],
      [field]: value
    }
    dispatch({
      type: 'SET_QUESTIONS',
      payload: questions
    })
  }

  const deleteQuestion = (index) => {
    const questions = [...state.questions]
    if (questions[index].id) {
      deletedQuestions.push(questions[index].id)
    }
    questions.splice(index, 1)
    dispatch({
      type: 'SET_QUESTIONS',
      payload: questions
    })
  }

  const duplicateQuestion = (index) => {
    const questions = [...state.questions]
    questions.splice(index + 1, 0, { ...questions[index], id: null })
    dispatch({
      type: 'SET_QUESTIONS',
      payload: questions
    })
  }

  const addSurvey = () => {
    return new Promise((resolve, reject) => {
      dispatch({ type: 'SAVE' })
      const { data, questions } = state
      const sectionsIndex = [0]
      const questionsId = []
      let id
      firebase.firestore().collection('surveys').add({
        ...data,
        segmented: data.onlyCampus.length > 0 && true,
        countResults: 0,
        lastUpdate: firebase.firestore.FieldValue.serverTimestamp()
      })
        .then((docRef) => {
          const batch = firebase.firestore().batch()
          id = docRef.id
          questions.forEach((question, i) => {
            if (question.type === 'section') {
              if (i !== 0) {
                sectionsIndex.push(i)
              }
              questionsId.push('_section')
            } else {
              const trimQuestion = Object.fromEntries(Object.entries(question).filter(([_, value]) => value != null))
              delete trimQuestion.editable
              delete trimQuestion.id
              const docRef = firebase.firestore().collection('surveys').doc(id).collection('questions').doc()
              questionsId.push(docRef.id)
              batch.set(docRef, { ...trimQuestion, order: i + 1 })
            }
          })
          return batch.commit()
        })
        .then(() => {
          if (sectionsIndex.length > 1) {
            const sections = {}
            let nextSection
            sectionsIndex.forEach((sectionIndex, i) => {
              const sectionNumber = i + 1
              nextSection = sectionsIndex[sectionNumber]
              if (sectionIndex === 0) {
                let startAt = 0
                if (questions[0].type === 'section') {
                  startAt = 1
                }
                sections[sectionNumber] = {
                  questions: questionsId.slice(startAt, nextSection)
                }
              } else {
                sections[sectionNumber] = {
                  title: questions[sectionIndex].question,
                  questions: questionsId.slice(sectionIndex + 1, nextSection)
                }
                if (questions[sectionIndex].description) {
                  sections[sectionNumber].description = questions[sectionIndex].description
                }
              }
            })
            return firebase.firestore().collection('surveys').doc(id).set({ sections: sections }, { merge: true })
          }
        })
        .then(() => {
          dispatch({ type: 'SAVE_SUCCESS' })
          resolve()
        })
        .catch((error) => {
          dispatch({
            type: 'ERROR',
            payload: error.message
          })
          reject(error.message)
        })
    })
  }

  const editSurvey = (id) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: 'SAVE' })
      const { data, questions } = state
      const sectionsIndex = [0]
      const questionsId = []
      firebase.firestore().collection('surveys').doc(id).set({
        ...data,
        segmented: data.onlyCampus.length > 0 && true,
        lastUpdate: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge: true })
        .then(() => {
          const batch = firebase.firestore().batch()
          questions.forEach((question, i) => {
            if (question.type === 'section') {
              if (i !== 0) {
                sectionsIndex.push(i)
              }
              questionsId.push('_section')
              if (question.id) {
                deletedQuestions.push(question.id)
              }
            } else {
              const trimQuestion = Object.fromEntries(Object.entries(question).filter(([_, value]) => value != null))
              delete trimQuestion.editable
              delete trimQuestion.order
              delete trimQuestion.id
              let docRef
              if (question.id) {
                docRef = firebase.firestore().collection('surveys').doc(id).collection('questions').doc(question.id)
                questionsId.push(question.id)
              } else {
                docRef = firebase.firestore().collection('surveys').doc(id).collection('questions').doc()
                questionsId.push(docRef.id)
              }
              batch.set(docRef, { ...trimQuestion, order: i + 1 })
            }
          })
          if (deletedQuestions.length) {
            deletedQuestions.forEach((questionId) => {
              const docRef = firebase.firestore().collection('surveys').doc(id).collection('questions').doc(questionId)
              batch.delete(docRef)
            })
          }
          return batch.commit()
        })
        .then(() => {
          if (sectionsIndex.length > 1) {
            const sections = {}
            let nextSection
            sectionsIndex.forEach((sectionIndex, i) => {
              const sectionNumber = i + 1
              nextSection = sectionsIndex[sectionNumber]
              if (sectionIndex === 0) {
                let startAt = 0
                if (questions[0].type === 'section') {
                  startAt = 1
                }
                sections[sectionNumber] = {
                  questions: questionsId.slice(startAt, nextSection)
                }
              } else {
                sections[sectionNumber] = {
                  title: questions[sectionIndex].question,
                  questions: questionsId.slice(sectionIndex + 1, nextSection)
                }
                if (questions[sectionIndex].description) {
                  sections[sectionNumber].description = questions[sectionIndex].description
                }
              }
            })
            return firebase.firestore().collection('surveys').doc(id).update({ sections: sections })
          } else {
            return firebase.firestore().collection('surveys').doc(id).update({ sections: firebase.firestore.FieldValue.delete() })
          }
        })
        .then(() => {
          dispatch({ type: 'SAVE_SUCCESS' })
          resolve()
        })
        .catch((error) => {
          dispatch({
            type: 'ERROR',
            payload: error.message
          })
          reject(error.message)
        })
    })
  }

  const deleteSurvey = (id) => {
    return new Promise((resolve, reject) => {
      dispatch({ type: 'DELETE' })
      const deleteFunction = firebase.functions().httpsCallable('deleteSurvey')
      deleteFunction({ id })
        .then(() => {
          dispatch({ type: 'DELETE_SUCCESS' })
          resolve()
        })
        .catch((error) => {
          dispatch({
            type: 'ERROR',
            payload: error.message
          })
          reject(error)
        })
    })
  }

  useEffect(() => {
    if (id) {
      dispatch({ type: 'LOAD' })
      const newState = {}
      firebase.firestore().collection('surveys').doc(id).get()
        .then((doc) => {
          if (doc.exists) {
            newState.data = doc.data()
            if (isTemplate) {
              newState.data.template = false
            }
          }
          return firebase.firestore().collection('surveys').doc(id).collection('questions').get()
        })
        .then((docs) => {
          const questions = []
          docs.forEach((doc) => {
            questions.push({ id: doc.id, editable: false, ...doc.data() })
          })
          if (questions.length) questions.sort((a, b) => a.order > b.order ? 1 : b.order > a.order ? -1 : 0)

          if (newState.data?.sections) {
            const sections = newState.data.sections
            delete sections[1]
            Object.keys(sections).forEach((section, i) => {
              const order = questions.length > 0 ? questions.find((question) => question.id === sections[section].questions[0]).order - 1 : i
              console.log(order)
              const item = {
                question: sections[section].title,
                type: 'section',
                options: null,
                mandatory: false,
                subQuestion: null,
                description: sections[section].description,
                editable: false,
                order: order
              }
              questions.splice(order - 1, 0, item)
            })
          }
          newState.questions = questions
          dispatch({
            type: 'LOAD_SUCCESS',
            payload: newState
          })
        })
        .catch((error) => {
          dispatch({
            type: 'ERROR',
            payload: error.message
          })
        })
    }
    return () => { deletedQuestions.length = 0 }
  }, [])

  return { state, setData, addQuestion, sortQuestion, setQuestion, deleteQuestion, duplicateQuestion, addSurvey, editSurvey, deleteSurvey }
}

export default useSurveyAsAdmin
