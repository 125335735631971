import React from 'react'
import style from './Layout.module.css'

import { Layout as LayoutAntd, Avatar, Dropdown, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from '../../images/escudo_dark.svg'

import useAuth from '../../hooks/useAuth'

const { Header, Content } = LayoutAntd

const Layout = ({ children }) => {
  const { user, signOut } = useAuth()

  return (
    <LayoutAntd className={style.container}>
      <Header className={style.header}>
        <div className={style.wrapper}>
          <Link to='/' className={style.title}>
            <Logo className={style.logo} />
            <span>Control de calidad UVA</span>
          </Link>
          <Dropdown overlay={() => AccountMenu({ signOut })} trigger={['click']} placement='bottomRight'>
            <Avatar size='large' style={{ backgroundColor: '#961A42', cursor: 'pointer' }}>{user.displayName}</Avatar>
          </Dropdown>
        </div>
      </Header>
      <Content className={style.content}>
        <div className={style.wrapper}>
          {children}
        </div>
      </Content>
    </LayoutAntd>
  )
}

const AccountMenu = ({ signOut }) => (
  <Menu>
    <Menu.Item key='0'>
      <Link to='/account/profile'>Editar mi perfil</Link>
    </Menu.Item>
    <Menu.Item key='1'>
      <Link to='/account/email'>Cambiar mi email</Link>
    </Menu.Item>
    <Menu.Item key='2'>
      <Link to='/account/password'>Cambiar mi contraseña</Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key='4' onClick={() => setTimeout(() => signOut(), 100)}>Cerrar sesión</Menu.Item>
  </Menu>
)

export default Layout
