import React from 'react'

import style from './Profile.module.css'

import { Typography, Row, Col, Button, Skeleton } from 'antd'
import { useHistory } from 'react-router-dom'

import useProfile from '../../hooks/useProfile'

const { Text } = Typography

const Profile = () => {
  const { profile, loading } = useProfile()
  const history = useHistory()

  return (
    <div className={style.container}>
      {loading
        ? (
          <>
            <Skeleton active paragraph={{ rows: 7 }} />
            <Row gutter={[12, 12]}>
              <Col xs={{ span: 16, offset: 8 }}><Skeleton.Button active /></Col>
            </Row>
          </>
          )
        : (
          <Row gutter={[0, 12]}>
            <Col xs={{ span: 8 }}><Text type='secondary'>Nombre:</Text></Col>
            <Col xs={{ span: 16 }}><Text>{profile.firstName}</Text></Col>
            <Col xs={{ span: 8 }}><Text type='secondary'>Email:</Text></Col>
            <Col xs={{ span: 16 }}><Text>{profile.email}</Text></Col>
            <Col xs={{ span: 8 }}><Text type='secondary'>Teléfono:</Text></Col>
            <Col xs={{ span: 16 }}><Text>{profile.tel}</Text></Col>
            <Col xs={{ span: 8 }}><Text type='secondary'>Matrícula:</Text></Col>
            <Col xs={{ span: 16 }}><Text>{profile.code}</Text></Col>
            <Col xs={{ span: 8 }}><Text type='secondary'>Campus:</Text></Col>
            <Col xs={{ span: 16 }}><Text>{profile.campus}</Text></Col>
            <Col xs={{ span: 8 }}><Text type='secondary'>Carrera:</Text></Col>
            <Col xs={{ span: 16 }}><Text>{profile.carrera}</Text></Col>
            <Col xs={{ span: 8 }}><Text type='secondary'>Grado:</Text></Col>
            <Col xs={{ span: 16 }}><Text>{profile.grado}</Text></Col>
            <Col xs={{ span: 8 }}><Text type='secondary'>Sistema:</Text></Col>
            <Col xs={{ span: 16 }}><Text>{profile.sistema}</Text></Col>
            <Col xs={{ offset: 8, span: 16 }} style={{ marginTop: '0.5rem' }}><Button type='secondary' onClick={() => history.push('/account/profile')}>Actualizar perfil</Button></Col>
          </Row>
          )}
    </div>
  )
}

export default Profile
