import React from 'react'

import { Card, Dropdown, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import PageHeader from '../layout/PageHeader'
import { ItemAdmin, ItemLoading } from '../commons/ListItem'
import Empty from '../modules/Empty'

import useSurveysAsAdmin from '../../hooks/useSurveysAsAdmin'

const Archive = () => {
  const [surveys, toggleSurvey] = useSurveysAsAdmin(true)
  const history = useHistory()

  const SubMenu = () => {
    const templates = surveys.data.filter((survey) => survey.template)
    return (
      <Menu>
        <Menu.ItemGroup key='g1' title='Nueva encuesta desde'>
          {templates.length
            ? templates.map((template, i) => <Menu.Item key={i} onClick={() => history.push('/new/' + template.id)}>{template.title}</Menu.Item>)
            : <Menu.Item disabled>En este momento no hay encuestas definidas como plantilla</Menu.Item>}
        </Menu.ItemGroup>
      </Menu>
    )
  }

  return (
    <>
      <PageHeader
        title='Archivo de encuestas'
        subtitle='Administrador'
        back='Inicio'
        extra={[
          <Dropdown.Button
            key='1'
            overlay={<SubMenu />}
            icon={<DownOutlined />}
            size='large'
            type='primary'
            trigger={['click']}
            onClick={() => history.push('/new')}
          >
            Nueva encuesta
          </Dropdown.Button>
        ]}
      />
      {surveys.loading
        ? (
          <Card>
            <ItemLoading />
            <ItemLoading />
            <ItemLoading />
          </Card>
          )
        : surveys.data.length === 0
          ? <Empty
              title='No se han levantado encuestas todavía.'
              description='Comienza por agregar tu primer encuesta en el botón superior de la derecha, una vez publicada se mostrará en este listado y podrás gestionarla.'
            />
          : (
            <Card>
              {surveys.data.map((survey) => <ItemAdmin key={survey.id} {...survey} toggleSurvey={toggleSurvey} />)}
            </Card>
            )}
    </>
  )
}

export default Archive
