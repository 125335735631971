import React from 'react'

import style from './Question.module.css'

import { Input, Radio, Checkbox, Select, Typography } from 'antd'

const { Title, Text } = Typography

const Question = ({ question, type, options, subQuestion, description, mandatory, index, value, onChange }) => {
  return (
    <div>
      {type === 'title'
        ? <Title level={3} style={{ color: '#11203F' }}>{question}</Title>
        : <div className={`${style.question} ${mandatory ? style.required : ''}`}>{`${index}) ${question}`}</div>}
      <div className={style.answer}>
        <Answer type={type} options={options} subQuestion={subQuestion} description={description} value={value} onChange={onChange} />
      </div>
    </div>
  )
}

const Answer = ({ type, options, subQuestion, description, value, onChange }) => {
  switch (type) {
    case 'text':
      return (
        <Input
          placeholder='Introduce tu respuesta'
          onChange={(ev) => onChange(ev.target.value)}
          value={value}
        />
      )
    case 'textarea':
      return (
        <Input.TextArea
          placeholder='Introduce tu respuesta'
          autoSize={{ minRows: 2, maxRows: 6 }}
          onChange={(ev) => onChange(ev.target.value)}
          value={value}
        />
      )
    case 'radio':
      return (
        <Radio.Group value={value}>
          {options?.map((option) => (
            <Radio
              key={option}
              value={option}
              className={style.radio}
              onChange={(ev) => onChange(ev.target.value)}
            >
              {option}
            </Radio>)
          )}
        </Radio.Group>
      )
    case 'checkbox':
      return (
        <Checkbox.Group onChange={(values) => onChange(values)} value={value}>
          {options?.map((option) => (
            <div key={option}>
              <Checkbox
                value={option}
                className={style.checkbox}
              >
                {option}
              </Checkbox>
            </div>
          ))}
        </Checkbox.Group>
      )
    case 'select':
      return (
        <Select value={value} style={{ width: 180 }} onChange={(values) => onChange(values)}>
          {options?.map((option) => (
            <Select.Option
              key={option}
              value={option}
            >
              {option}
            </Select.Option>)
          )}
        </Select>
      )
    case 'condition':
      return <Conditional value={value} options={options} subQuestion={subQuestion} onChange={onChange} />
    case 'title':
      return <Text type='secondary'>{description}</Text>
    default:
      return null
  }
}

const Conditional = ({ value, options, subQuestion, onChange }) => {
  console.log(options, value)
  return (
    <>
      <Radio
        className={style.radio}
        onChange={() => {
          onChange('')
        }}
        checked={value === '' || (value && value !== options[1])}
      >
        {options[0]}
      </Radio>
      <Radio
        className={style.radio}
        onChange={() => {
          onChange(options[1])
        }}
        checked={(value === options[1])}
      >
        {options[1]}
      </Radio>
      {(value === '' || (value && value !== options[1])) && (
        <>
          <div className={style.conditionalQuestion}>{subQuestion}</div>
          <Input
            placeholder='Introduce tu respuesta'
            onChange={(ev) => onChange(ev.target.value)}
            value={value}
          />
        </>
      )}
    </>
  )
}

export default Question
