import React, { useState, useEffect } from 'react'

import { Typography, Form, Card, Button, Input, Select, Divider, message, Skeleton } from 'antd'

import PageHeader from '../layout/PageHeader'

import useAuth from '../../hooks/useAuth'
import useProfile from '../../hooks/useProfile'
import useCampusData from '../../hooks/useCampusData'

const { Text } = Typography
const { Option, OptGroup } = Select

const Profile = () => {
  const { user } = useAuth()
  const { profile, loading, updateProfile } = useProfile()
  const [saving, setSaving] = useState(false)
  const [form] = Form.useForm()
  const { campus, careers, getCareerByCampus, sistemas, cuatrimestres, semestres } = useCampusData()

  const handleFinish = (values) => {
    setSaving(true)
    const update = { ...values }
    if (!user.admin) {
      if (profile.campus !== update.campus) {
        update.campus = campus.find((item) => item.id === update.campus).name
      }
      update.code = profile.code
    }
    updateProfile(update)
      .then(() => {
        message.success('Tu perfil se ha modificado correctamente')
        setSaving(false)
      })
      .catch((error) => {
        message.error(error)
        setSaving(false)
      })
  }

  useEffect(() => {
    if (Object.keys(profile).length !== 0) {
      form.setFieldsValue(profile)
      if (campus.length) {
        if (profile.campus) {
          const campusId = campus.find((item) => item.name === profile.campus).id
          getCareerByCampus(campusId)
        }
      }
    }
  }, [profile, campus])

  return (
    <>
      <PageHeader
        title='Editar perfil'
        subtitle={user.admin ? 'Administrador' : 'Alumno'}
        back='Inicio'
      >
        <Text type='secondary'>Aquí podrás editar tus datos. Es importante que la información sea verídica.</Text>
      </PageHeader>
      <Card>
        <Form
          form={form}
          onFinish={handleFinish}
          colon={false}
          labelCol={{ sm: { span: 24 }, md: { span: 6 } }}
          wrapperCol={{ sm: { span: 24 }, md: { span: 'auto' } }}
          style={{ maxWidth: '720px', margin: '0 auto' }}
        >
          <Form.Item
            label='Nombre(s)'
            name='firstName'
            rules={[
              {
                required: true,
                message: 'Necesitas ingresar tu nombre'
              }
            ]}
          >
            {loading ? <Skeleton.Input active /> : <Input placeholder='Tu nombre' />}
          </Form.Item>
          <Form.Item
            label='Apellido paterno'
            name='lastName'
            rules={[
              {
                required: true,
                message: 'Necesitas ingresar tu apellido paterno'
              }
            ]}
          >
            {loading ? <Skeleton.Input active /> : <Input placeholder='Tu apellido paterno' />}
          </Form.Item>
          <Form.Item
            label='Apellido materno'
            name='mLastName'
            rules={[
              {
                required: true,
                message: 'Necesitas ingresar tu apellido materno'
              }
            ]}
          >
            {loading ? <Skeleton.Input active /> : <Input placeholder='Tu apellido materno' />}
          </Form.Item>
          <Divider />
          <Form.Item
            label='Teléfono'
            name='tel'
            rules={[
              {
                required: true,
                message: 'Necesitas ingresar tu teléfono'
              },
              {
                min: 10,
                message: 'Tu número telefónico debe contener 10 dígitos'
              }
            ]}
          >
            {loading
              ? <Skeleton.Input active />
              : (
                <Input
                  placeholder='Tu número telefónico'
                  maxLength={10}
                  onChange={(ev) => {
                    const value = ev.target.value.replace(/\D/g, '')
                    form.setFieldsValue({
                      tel: value
                    })
                  }}
                />
                )}
          </Form.Item>
          {!user.admin &&
            <>
              <Divider />
              <Form.Item
                label='Matrícula de alumno'
                name='code'
              >
                {loading ? <Skeleton.Input active /> : <Input readOnly value='000000' bordered={false} />}
              </Form.Item>
              <Form.Item
                label='Campus'
                name='campus'
                rules={[
                  {
                    required: true,
                    message: 'No olvides seleccionar tu Campus'
                  }
                ]}
              >
                {loading
                  ? <Skeleton.Input active />
                  : (
                    <Select placeholder='Selecciona un campus' onSelect={(value) => { getCareerByCampus(value); form.setFieldsValue({ carrera: undefined }) }}>
                      {campus.map(({ id, name }) => <Option key={id}>{name}</Option>)}
                    </Select>
                    )}
              </Form.Item>
              <Form.Item
                label='Carrera'
                name='carrera'
                rules={[
                  {
                    required: true,
                    message: 'No olvides seleccionar tu carrera'
                  }
                ]}
              >
                {loading
                  ? <Skeleton.Input active />
                  : (
                    <Select placeholder='Selecciona una carrera'>
                      {careers.map(({ id, name }) => <Option key={id} value={name}>{name}</Option>)}
                    </Select>
                    )}
              </Form.Item>
              <Form.Item
                label='Grado'
                name='grado'
                rules={[
                  {
                    required: true,
                    message: 'No olvides seleccionar tu grado'
                  }
                ]}
              >
                {loading
                  ? <Skeleton.Input active />
                  : (
                    <Select placeholder='Selecciona tu grado en curso'>
                      <OptGroup label='Cuatrimestres'>
                        {cuatrimestres.map((cuatrimestre) => <Option key={cuatrimestre}>{cuatrimestre}</Option>)}
                      </OptGroup>
                      <OptGroup label='Semestres'>
                        {semestres.map((semestre) => <Option key={semestre}>{semestre}</Option>)}
                      </OptGroup>
                    </Select>
                    )}
              </Form.Item>
              <Form.Item
                label='Sistema'
                name='sistema'
                rules={[
                  {
                    required: true,
                    message: 'No olvides seleccionar el sistema'
                  }
                ]}
              >
                {loading
                  ? <Skeleton.Input active />
                  : (
                    <Select placeholder='Selecciona tu sistema'>
                      {sistemas.map((sistema) => <Option key={sistema}>{sistema}</Option>)}
                    </Select>
                    )}
              </Form.Item>
            </>}
          <Form.Item wrapperCol={{ xs: { span: 24 }, md: { offset: 6, span: 'auto' } }}>
            <Button type='primary' size='large' htmlType='submit' loading={saving}>Guardar cambios</Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  )
}

export default Profile
