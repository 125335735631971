import React, { useState } from 'react'

import { Form, Input, Button, message } from 'antd'
import { Link } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'

const PasswordRecovery = () => {
  const { sendPasswordResetEmail } = useAuth()
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const handleFinish = ({ email }) => {
    setLoading(true)
    sendPasswordResetEmail(email)
      .then(() => {
        setLoading(false)
        form.setFieldsValue({
          email: ''
        })
        message.success('Revisa tu bandeja de correo electrónico, un email de recuperación de contraseña ha sido enviado.')
      })
      .catch((error) => {
        setLoading(false)
        message.error(error)
      })
  }

  return (
    <>
      <div style={{ marginBottom: '1rem' }}>Introduce tu email y te enviaremos un correo para restablecer tu contraseña.</div>
      <Form layout='vertical' form={form} onFinish={handleFinish} style={{ textAlign: 'left' }}>
        <Form.Item
          label='Email'
          name='email'
          rules={[
            {
              required: true,
              message: 'Necesitas ingresar tu cuenta de correo'
            },
            {
              pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: 'La cuenta no es un email válido'
            }
          ]}
        >
          <Input placeholder='Cuenta de correo electrónico' size='large' type='email' />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button type='primary' htmlType='submit' size='large' loading={loading}>Enviar correo</Button>
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
          <Link to='/login'>Regresa a iniciar sesión</Link>
        </Form.Item>
      </Form>
    </>
  )
}

export default PasswordRecovery
