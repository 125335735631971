import React from 'react'

import style from './Empty.module.css'

import { Typography } from 'antd'
import { ReactComponent as Glyph } from '../../images/adorno.svg'

const { Title, Text } = Typography

const Empty = ({ title, description }) => {
  return (
    <div className={style.container}>
      <Title type='secondary'>{title}</Title>
      <Text type='secondary'>{description}</Text>
      <div className={style.glyph}>
        <Glyph />
      </div>
    </div>
  )
}

export default Empty
