import { useEffect, useReducer } from 'react'

import firebase from '../lib/firebase'

import useProfile from './useProfile'

const initialState = {
  data: [],
  loading: false,
  error: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD':
      return {
        ...state,
        loading: true
      }
    case 'SUCCESS':
      return {
        data: action.payload,
        loading: false,
        error: null
      }
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

const useSurveys = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { profile, loading } = useProfile()

  useEffect(() => {
    dispatch({ type: 'LOAD' })
    if (loading) {
      return null
    }
    const surveysData = []
    let queryAll, querySegmented
    firebase.firestore().collection('surveys').where('active', '==', true).where('onlyCampus', 'array-contains', profile.campus).get()
      .then((documents) => {
        querySegmented = documents
        return firebase.firestore().collection('surveys').where('active', '==', true).where('segmented', '==', false).get()
      })
      .then((documents) => {
        queryAll = documents
        const promises = []
        let count = 0
        querySegmented.forEach((doc) => {
          promises[count] = new Promise((resolve, reject) => {
            firebase.firestore().collection('surveys').doc(doc.id).collection('answers').doc(profile.uid).get()
              .then((docAnswers) => {
                if (docAnswers.exists) {
                  surveysData.push({ id: doc.id, completed: true, ...doc.data() })
                } else {
                  surveysData.push({ id: doc.id, ...doc.data() })
                }
                resolve()
              })
              .catch((error) => {
                reject(error)
              })
          })
          count++
        })
        return Promise.all(promises)
      })
      .then(() => {
        const promises = []
        let count = 0
        queryAll.forEach((doc) => {
          promises[count] = new Promise((resolve, reject) => {
            firebase.firestore().collection('surveys').doc(doc.id).collection('answers').doc(profile.uid).get()
              .then((docAnswers) => {
                if (docAnswers.exists) {
                  surveysData.push({ id: doc.id, completed: true, ...doc.data() })
                } else {
                  surveysData.push({ id: doc.id, ...doc.data() })
                }
                resolve()
              })
              .catch((error) => {
                reject(error)
              })
          })
          count++
        })
        return Promise.all(promises)
      })
      .then(() => {
        dispatch({
          type: 'SUCCESS',
          payload: surveysData
        })
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          payload: error.message
        })
        console.log(error)
      })
  }, [loading])

  return [state]
}

export default useSurveys
