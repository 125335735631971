const setDates = (epochTime) => {
  const date = new Date(0)
  date.setUTCSeconds(epochTime)

  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  // const msPerYear = msPerDay * 365
  const currentDate = Date.now()
  const elapsedTime = currentDate - date

  const rtf = new Intl.RelativeTimeFormat('es', { numeric: 'auto' })
  let formatedDate

  if (elapsedTime < msPerMinute) {
    formatedDate = rtf.format(-Math.floor(elapsedTime / 1000), 'seconds')
  } else if (elapsedTime < msPerHour) {
    formatedDate = rtf.format(-Math.floor(elapsedTime / msPerMinute), 'minutes')
  } else if (elapsedTime < msPerDay) {
    formatedDate = rtf.format(-Math.floor(elapsedTime / msPerHour), 'hours')
  } else if (elapsedTime < msPerMonth) {
    formatedDate = rtf.format(-Math.floor(elapsedTime / msPerDay), 'days')
  } else {
    formatedDate = new Intl.DateTimeFormat('es').format(date)
  }

  return formatedDate
}

export default setDates
