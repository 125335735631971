import React, { useEffect, useState } from 'react'

import { Typography, Row, Col, Card, Button, Dropdown, Menu, message, Modal } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

import PageHeader from '../layout/PageHeader'
import { ItemAdmin, ItemAlumno, ItemLoading } from '../commons/ListItem'
import Log from '../modules/Log'
import Empty from '../modules/Empty'
import Profile from '../modules/Profile'

import useAuth from '../../hooks/useAuth'
import useSurveys from '../../hooks/useSurveys'
import useSurveysAsAdmin from '../../hooks/useSurveysAsAdmin'
import useProfile from '../../hooks/useProfile'

const { Title } = Typography

const Dashboard = () => {
  const { user } = useAuth()

  return (
    user.admin
      ? <Admin user={user} />
      : <Alumno user={user} />
  )
}

const Admin = ({ user }) => {
  const [surveys, toggleSurvey] = useSurveysAsAdmin()
  const history = useHistory()

  useEffect(() => {
    if (surveys.error) {
      message.error(surveys.error)
    }
  }, [surveys])

  const SubMenu = () => {
    const templates = surveys.data.filter((survey) => survey.template)
    return (
      <Menu>
        <Menu.ItemGroup key='g1' title='Nueva encuesta desde'>
          {templates.length
            ? templates.map((template, i) => <Menu.Item key={i} onClick={() => history.push('/new/' + template.id)}>{template.title}</Menu.Item>)
            : <Menu.Item disabled>En este momento no hay encuestas definidas como plantilla</Menu.Item>}
        </Menu.ItemGroup>
      </Menu>
    )
  }

  return (
    <>
      <PageHeader
        title={`Bienvenido, ${user.displayName}`}
        subtitle='Administrador'
        back={false}
        extra={[
          <Dropdown.Button
            key='1'
            overlay={<SubMenu />}
            icon={<DownOutlined />}
            size='large'
            type='primary'
            trigger={['click']}
            onClick={() => history.push('/new')}
          >
            Nueva encuesta
          </Dropdown.Button>
        ]}
      />
      <Row gutter={[24, 24]}>
        <Col xs={{ span: 24 }} md={{ span: 16 }}>
          {surveys.loading
            ? (
              <Card>
                <Title level={2} style={{ marginBottom: '1.5rem' }}>Últimas encuestas</Title>
                <ItemLoading />
                <ItemLoading />
                <ItemLoading />
              </Card>
              )
            : surveys.data.length === 0
              ? <Empty
                  title='No se han levantado encuestas todavía.'
                  description='Comienza por agregar tu primer encuesta en el botón superior de la derecha, una vez publicada se mostrará en este listado y podrás gestionarla.'
                />
              : (
                <Card>
                  <Title level={2} style={{ marginBottom: '1.5rem' }}>Últimas encuestas</Title>
                  {surveys.data.map((survey) => <ItemAdmin key={survey.id} {...survey} toggleSurvey={toggleSurvey} />)}
                  <Button onClick={() => history.push('/archive')}>Ver todas las encuestas</Button>
                </Card>
                )}
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <Card>
            <Title level={2} style={{ marginBottom: '1.5rem' }}>Actividad reciente</Title>
            <Log />
          </Card>
        </Col>
      </Row>
    </>
  )
}

const Alumno = ({ user }) => {
  const [surveys] = useSurveys()
  const { profile, loading } = useProfile()
  const history = useHistory()

  useEffect(() => {
    if (surveys.error) {
      message.error(surveys.error)
    }
  }, [surveys])

  useEffect(() => {
    if (loading) return
    Modal.confirm({
      title: `¡Es momento de actualizar tu perfil, ${profile.firstName}!`,
      content: (
        <table>
          <tbody>
            <tr>
              <td>Grado:</td>
              <td><b>{profile.grado}</b></td>
            </tr>
            <tr>
              <td>Carrera:</td>
              <td><b>{profile.carrera}</b></td>
            </tr>
            <tr>
              <td>Sistema:</td>
              <td><b>{profile.sistema}</b></td>
            </tr>
          </tbody>
        </table>
      ),
      cancelText: 'Mis datos son correctos',
      okText: 'Actualizar perfil',
      onOk () {
        history.push('/account/profile')
      },
      onCancel () {
        Modal.destroyAll()
      }
    })
  }, [loading])

  return (
    <>
      <PageHeader
        title={`Bienvenido, ${user.displayName}`}
        subtitle='Alumno'
        back={false}
      />
      <Row gutter={[24, 24]}>
        <Col xs={{ span: 24 }} md={{ span: 14 }}>
          {surveys.loading
            ? (
              <Card>
                <Title level={2} style={{ marginBottom: '1.5rem' }}>Encuesta activa</Title>
                <ItemLoading />
              </Card>
              )
            : surveys.data.length === 0
              ? <Empty
                  title='En este momento no hay encuestas activas.'
                  description={<>Regresa cuando se te indique que haya una encuesta vigente.<br />¡Muchas gracias por tu interés!</>}
                />
              : (
                <Card>
                  <Title level={2} style={{ marginBottom: '1.5rem' }}>Encuesta activa</Title>
                  {surveys.data.map((survey) => <ItemAlumno key={survey.id} {...survey} />)}
                </Card>
                )}
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 10 }}>
          <Card>
            <Title level={2} style={{ marginBottom: '1.5rem' }}>Tu perfil</Title>
            <Profile />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Dashboard
