import React, { useState } from 'react'

import { Row, Col, Steps, Typography, Form, Input, Button, Space, Select, message } from 'antd'
import useCampusData from '../../hooks/useCampusData'
import useProfile from '../../hooks/useProfile'

const { Title, Text } = Typography
const { Step } = Steps
const { Option, OptGroup } = Select

let profileFormData = {}

const CreateAccount = () => {
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { campus, careers, getCareerByCampus, sistemas, cuatrimestres, semestres } = useCampusData()
  const { updateProfile } = useProfile()

  const handleFinish = (values) => {
    setLoading(true)
    const update = { ...profileFormData, ...values }
    delete update.codeConfirm
    update.campus = campus.find((item) => item.id === update.campus).name
    updateProfile(update)
      .catch((error) => {
        setLoading(false)
        message.error(error)
      })
  }

  const ProfileForm = (
    <Form
      layout='vertical'
      form={form}
      onFinish={(values) => {
        profileFormData = values
        setStep(1)
      }}
    >
      <Form.Item
        label='Nombre(s)'
        name='firstName'
        rules={[
          {
            required: true,
            message: 'Necesitas ingresar tu nombre'
          }
        ]}
      >
        <Input placeholder='Tu nombre' />
      </Form.Item>
      <Row gutter={24}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Form.Item
            label='Apellido paterno'
            name='lastName'
            rules={[
              {
                required: true,
                message: 'Necesitas ingresar tu apellido paterno'
              }
            ]}
          >
            <Input placeholder='Tu apellido paterno' />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Form.Item
            label='Apellido materno'
            name='mLastName'
            rules={[
              {
                required: true,
                message: 'Necesitas ingresar tu apellido materno'
              }
            ]}
          >
            <Input placeholder='Tu apellido materno' />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label='Teléfono'
        name='tel'
        rules={[
          {
            required: true,
            message: 'Necesitas ingresar tu teléfono'
          },
          {
            min: 10,
            message: 'Tu número telefónico debe contener 10 dígitos'
          }
        ]}
      >
        <Input
          placeholder='Tu número telefónico'
          maxLength={10}
          onChange={(ev) => {
            const value = ev.target.value.replace(/\D/g, '')
            form.setFieldsValue({
              tel: value
            })
          }}
        />
      </Form.Item>
      <Form.Item style={{ textAlign: 'right' }}>
        <Button
          type='primary'
          size='large'
          htmlType='submit'
        >
          Siguiente
        </Button>
      </Form.Item>
    </Form>
  )

  const UVAForm = (
    <Form
      layout='vertical'
      form={form}
      onFinish={handleFinish}
    >
      <Text type='warning'>Es necesario que prestes atención a la hora de introducir tu matrícula, ya que <b>no te será posible volverlo a cambiar</b>.</Text>
      <Row gutter={24} style={{ marginTop: '0.5rem' }}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Form.Item
            label='Matrícula de alumno'
            name='code'
            rules={[
              {
                required: true,
                message: 'Necesitas ingresar tu matrícula de alumno'
              }
            ]}
          >
            <Input placeholder='Tu matrícula' />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Form.Item
            label='Confirmar matrícula'
            name='codeConfirm'
            rules={[
              {
                required: true,
                message: 'Necesitas confirmar tu matrícula de alumno'
              },
              {
                validator: (_, value) => {
                  if (value === form.getFieldValue('code')) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error('Tus matrículas no coinciden'))
                }
              }
            ]}
          >
            <Input placeholder='Tu matrícula' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Form.Item
            label='Campus'
            name='campus'
            rules={[
              {
                required: true,
                message: 'No olvides seleccionar tu Campus'
              }
            ]}
          >
            <Select placeholder='Selecciona un campus' onSelect={(value) => { getCareerByCampus(value); form.setFieldsValue({ carrera: undefined }) }}>
              {campus.map(({ id, name }) => <Option key={id}>{name}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Form.Item
            label='Carrera'
            name='carrera'
            rules={[
              {
                required: true,
                message: 'No olvides seleccionar tu carrera'
              }
            ]}
          >
            <Select placeholder='Selecciona una carrera'>
              {careers.map(({ id, name }) => <Option key={id} value={name}>{name}</Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Form.Item
            label='Grado'
            name='grado'
            rules={[
              {
                required: true,
                message: 'No olvides seleccionar tu grado'
              }
            ]}
          >
            <Select placeholder='Selecciona tu grado en curso'>
              <OptGroup label='Cuatrimestres'>
                {cuatrimestres.map((cuatrimestre) => <Option key={cuatrimestre}>{cuatrimestre}</Option>)}
              </OptGroup>
              <OptGroup label='Semestres'>
                {semestres.map((semestre) => <Option key={semestre}>{semestre}</Option>)}
              </OptGroup>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <Form.Item
            label='Sistema'
            name='sistema'
            rules={[
              {
                required: true,
                message: 'No olvides seleccionar el sistema'
              }
            ]}
          >
            <Select placeholder='Selecciona tu sistema'>
              {sistemas.map((sistema) => <Option key={sistema}>{sistema}</Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item style={{ textAlign: 'right' }}>
        <Space size='large'>
          <Button size='large' onClick={() => setStep(0)}>Regresar</Button>
          <Button type='primary' size='large' htmlType='submit' loading={loading}>Finalizar</Button>
        </Space>
      </Form.Item>
    </Form>
  )

  return (
    <div style={{ maxWidth: '1168px', marginRight: 'auto', marginLeft: 'auto', paddingTop: '1.5rem', paddingRight: '1rem', paddingBottom: '1.5rem', paddingLeft: '1rem' }}>
      <Row>
        <Col xs={{ span: 24 }} md={{ span: 12, offset: 6 }}>
          <Steps
            direction='horizontal'
            current={step}
          >
            <Step title='Perfil de usuario' />
            <Step title='Perfil UVA' />
          </Steps>
        </Col>
      </Row>
      <div style={{ maxWidth: '720px', marginTop: '1rem', marginRight: 'auto', marginLeft: 'auto' }}>
        <div style={{ textAlign: 'center', padding: '1.5rem 0' }}>
          <Title level={2}>Vamos a validar y crear tu perfil</Title>
          <Text type='secondary'>Introduce los siguientes datos</Text>
        </div>
        {step === 0 ? ProfileForm : UVAForm}
      </div>
    </div>
  )
}

export default CreateAccount
