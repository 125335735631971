import React, { useState, useEffect } from 'react'

import { Card, Typography, Row, Col, Button, Space, message, Modal, Result, Skeleton } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'

import PageHeader from '../layout/PageHeader'
import Question from '../commons/Question'

import useSurvey from '../../hooks/useSurvey'

const { Title, Text } = Typography
const { confirm } = Modal

const Survey = () => {
  const { id } = useParams()
  const localAnswers = window.sessionStorage.getItem(id)
  const [survey, submitSurvey] = useSurvey(id)
  const [answers, setAnswers] = useState(localAnswers ? JSON.parse(localAnswers) : {})
  const [errors, setErrors] = useState({})
  const [section, setSection] = useState(1)
  const [result, setResult] = useState(null)
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  const handleSubmit = () => {
    validateData(section)
      .then(() => {
        confirm({
          title: 'Tu encuesta está a punto de enviarse',
          icon: <ExclamationCircleOutlined />,
          content: 'Al mandar tus respuestas ya no te será posible editar la encuesta. ¿Deseas proceder con el envío?',
          cancelText: 'Aún no',
          okText: 'Si, estoy listo',
          confirmLoading: saving,
          onOk () {
            setSaving(true)
            return submitSurvey(answers)
              .then(() => {
                setSaving(false)
                setResult('success')
              })
              .catch((error) => {
                setSaving(false)
                console.log(error)
                setResult('error')
              })
          }
        })
      })
      .catch((error) => {
        message.error(error.message)
      })
  }

  const nextSection = () => {
    validateData(section)
      .then(() => {
        setSection((section) => section + 1)
        window.scrollTo(0, 0)
      })
      .catch((error) => {
        message.error(error.message)
      })
  }

  const prevSection = () => {
    setSection((section) => section - 1)
    setErrors({})
    window.scrollTo(0, 0)
  }

  const validateData = (section) => {
    return new Promise((resolve, reject) => {
      const sectionAnswers = answers[section]
      const sectionQuestions = survey.data.sections[section].questions
      const sectionQuestionsRequired = []
      let errorsObject = {}
      sectionQuestions.forEach((question, i) => {
        if (question.mandatory) {
          sectionQuestionsRequired.push(i)
        }
      })
      sectionQuestionsRequired.forEach((index) => {
        const questionType = sectionQuestions[index].type
        const questionId = sectionQuestions[index].id
        if (sectionAnswers?.[questionId]) {
          const answer = sectionAnswers[questionId].value
          if (questionType === 'text' || questionType === 'textarea') {
            if (!answer || answer?.trim() === '') {
              errorsObject = { ...errorsObject, [index]: true }
            }
          } else if (questionType === 'radio' || questionType === 'select') {
            if (!answer) {
              errorsObject = { ...errorsObject, [index]: true }
            }
          } else if (questionType === 'checkbox') {
            if (!answer || !answer?.length) {
              errorsObject = { ...errorsObject, [index]: true }
            }
          } else if (questionType === 'condition') {
            if (!answer || answer?.trim() === '') {
              errorsObject = { ...errorsObject, [index]: true }
            }
          }
        } else {
          errorsObject = { ...errorsObject, [index]: true }
        }
      })
      if (Object.entries(errorsObject).length === 0) {
        setErrors({})
        resolve()
      } else {
        setErrors(errorsObject)
        reject(new Error('¡Revisa tus respuestas! Hay preguntas obligatorias sin contestar'))
      }
    })
  }

  const hasError = (index) => {
    return errors[index]
  }

  useEffect(() => {
    if (survey.error) {
      message.error(survey.error)
    }
  }, [survey])

  useEffect(() => {
    window.sessionStorage.setItem(id, JSON.stringify(answers))
  }, [answers])

  return (
    <>
      <PageHeader
        title='Contestar encuesta'
        subtitle='Alumno'
        back
      >
        <Text type='secondary'>Los campos marcados con * son obligatorios.</Text>
      </PageHeader>
      {result === 'success'
        ? (
          <Card>
            <Result
              status='success'
              title='Tu encuesta se ha enviado correctamente'
              subTitle='Texto de agradecimiento.'
              extra={[
                <Button key='home' onClick={() => history.push('/')}>Ir al inicio</Button>
              ]}
            />
          </Card>
          )
        : result === 'error'
          ? (
            <Card>
              <Result
                status='error'
                title='Ocurrió un error al enviar tus resultados'
                subTitle='Inténtalo nuevamente, si sigues teniendo problemas contácta a tu coordinador de vinculación para que te apoye.'
                extra={[
                  <Button key='home' onClick={() => setResult(null)}>Regresar</Button>,
                  <Button type='primary' key='again' onClick={() => handleSubmit()}>Reintentar envío</Button>
                ]}
              />
            </Card>
            )
          : survey.loading
            ? (
              <Row>
                <Col xs={{ span: 24 }} xl={{ span: 20, offset: 2 }}>
                  <Card style={{ marginBottom: '1.5rem' }}>
                    <Skeleton.Input active size='large' style={{ marginBottom: '1.25rem' }} />
                    <Skeleton active paragraph={{ rows: 2 }} title={false} />
                  </Card>
                </Col>
              </Row>
              )
            : (
              <form style={{ marginBottom: '2rem' }} onSubmit={(ev) => ev.preventDefault()}>
                <Row>
                  <Col xs={{ span: 24 }} xl={{ span: 20, offset: 2 }}>
                    {section === 1
                      ? (
                        <Card style={{ marginBottom: '1.5rem' }}>
                          <Title level={1} style={{ color: '#11203F' }}>{survey.data.title}</Title>
                          <Text type='secondary'>{survey.data.description}</Text>
                        </Card>
                        )
                      : (
                        <Card style={{ marginBottom: '1.5rem' }}>
                          <Title level={2} style={{ color: '#11203F' }}>{survey.data.sections[section]?.title}</Title>
                          {survey.data.sections[section]?.description && <Text type='secondary'>{survey.data.sections[section].description}</Text>}
                        </Card>
                        )}
                    {survey.data.sections && survey.data.sections[section].questions.map((item, i) => (
                      <Card style={hasError(i) ? { outline: '1px solid #FF4D4F', margin: '1.5rem 0' } : { margin: '1.5rem 0' }} key={'question' + i}>
                        <Question
                          {...item}
                          index={item.index}
                          value={answers[section]?.[item.id]?.value}
                          onChange={(value) => {
                            const newAnswers = { ...answers }
                            newAnswers[section] = { ...newAnswers[section], [item.id]: { value: value } }
                            setAnswers({ ...newAnswers })
                          }}
                        />
                        {hasError(i) && <div style={{ marginTop: '1rem', fontSize: '0.875rem', color: '#FF4D4F' }}><ExclamationCircleOutlined /> Esta pregunta es obligatoria.</div>}
                      </Card>
                    ))}
                    {!survey.data.sections
                      ? null
                      : Object.keys(survey.data.sections).length === 1
                        ? <Button type='primary' size='large' onClick={handleSubmit}>Terminar encuesta</Button>
                        : section === Object.keys(survey.data.sections).length
                          ? (
                            <Space size='middle'>
                              <Button size='large' onClick={prevSection}>Anterior</Button>
                              <Button type='primary' size='large' onClick={handleSubmit}>Terminar encuesta</Button>
                            </Space>
                            )
                          : section === 1
                            ? (
                              <Space size='middle'>
                                <Button size='large' onClick={nextSection}>Siguiente</Button>
                              </Space>
                              )
                            : (
                              <Space size='middle'>
                                <Button size='large' onClick={prevSection}>Anterior</Button>
                                <Button size='large' onClick={nextSection}>Siguiente</Button>
                              </Space>
                              )}
                  </Col>
                </Row>
              </form>
              )}
    </>
  )
}

export default Survey
