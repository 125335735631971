import React, { useState } from 'react'

import { Typography, Form, Card, Button, Input, Modal, message } from 'antd'

import PageHeader from '../layout/PageHeader'

import useProfile from '../../hooks/useProfile'
import useAuth from '../../hooks/useAuth'

const { Text } = Typography

const EmailChange = () => {
  const [showAuthentication, setShowAuthentication] = useState(false)
  const [loading, setLoading] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [formEmail] = Form.useForm()
  const [formAuth] = Form.useForm()
  const { updateEmail } = useProfile()
  const { user, reauthenticate } = useAuth()

  const handleFinish = (values) => {
    setLoading(true)
    updateEmail(values.email)
      .then((requiresRecentLogin) => {
        if (requiresRecentLogin) {
          setShowAuthentication(true)
        } else {
          message.success('Tu email se ha cambiado correctamente')
        }
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        message.error(error)
      })
  }

  const handleFinishAuthenticate = (values) => {
    setConfirmLoading(true)
    reauthenticate(values.password)
      .then(() => {
        const newEmail = formEmail.getFieldsValue().email
        return updateEmail(newEmail)
      })
      .then(() => {
        setShowAuthentication(false)
        message.success('Tu email se ha cambiado correctamente')
      })
      .catch((error) => {
        setConfirmLoading(false)
        message.error(error)
      })
  }

  return (
    <>
      <PageHeader
        title='Modificar email'
        subtitle={user.admin ? 'Administrador' : 'Alumno'}
        back='Inicio'
      >
        <Text type='secondary'>Escribe tu nuevo email, tu email será tu usuario para iniciar sesión en la plataforma. Se te pedirá tu contraseña para guardar el cambio.</Text>
      </PageHeader>
      <Card>
        <Form
          form={formEmail}
          onFinish={handleFinish}
          colon={false}
          labelCol={{ sm: { span: 24 }, md: { span: 6 } }}
          wrapperCol={{ sm: { span: 24 }, md: { span: 'auto' } }}
          style={{ maxWidth: '720px', margin: '0 auto' }}
        >
          <Form.Item label='Cuenta de correo actual'>
            <Text strong>{user.email}</Text>
          </Form.Item>
          <Form.Item
            label='Nuevo email'
            name='email'
            rules={[
              {
                required: true,
                message: 'Necesitas ingresar tu nueva cuenta de correo'
              },
              {
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: 'La cuenta no es un email válido'
              }
            ]}
          >
            <Input placeholder='Cuenta de correo' type='email' />
          </Form.Item>
          <Form.Item wrapperCol={{ xs: { span: 24 }, md: { offset: 6, span: 'auto' } }}>
            <Button type='primary' size='large' htmlType='submit' loading={loading}>Guardar cambios</Button>
          </Form.Item>
        </Form>
      </Card>
      <Modal
        title='Introduce tu contraseña'
        visible={showAuthentication}
        confirmLoading={confirmLoading}
        onOk={() => formAuth.submit()}
        onCancel={() => {
          formAuth.setFieldsValue({
            password: ''
          })
          setShowAuthentication(false)
        }}
      >
        <Text>Escribe tu contraseña para guardar los cambios.</Text>
        <Form
          form={formAuth}
          onFinish={handleFinishAuthenticate}
          style={{ marginTop: '1rem' }}
        >
          <Form.Item
            name='password'
            rules={[
              {
                required: true,
                message: 'Necesitas ingresar tu contraseña'
              }
            ]}
          >
            <Input.Password placeholder='Contraseña actual' />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default EmailChange
